import { supabase } from '../supabase';
import { UserModel } from '../models/user';
import { useAuthStore } from './authStore';

export const authService = {
  async initialize() {
    try {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) throw error;

      useAuthStore.getState().setSession(session);

      if (session?.user) {
        const profile = await UserModel.getCurrentUser();
        if (profile) {
          useAuthStore.getState().setUser(profile);
        }
      }
    } catch (error) {
      console.error('Auth initialization error:', error);
      useAuthStore.getState().setError(error instanceof Error ? error.message : 'Authentication failed');
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  async signUp(email: string, password: string, name: string) {
    try {
      useAuthStore.getState().setLoading(true);
      useAuthStore.getState().setError(null);

      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: { name },
          emailRedirectTo: window.location.origin
        }
      });

      if (error) throw error;

      if (data.user) {
        const profile = await UserModel.createProfile(data.user.id, {
          email,
          name,
          tokens: 2,
          role: 'user'
        });

        if (profile) {
          useAuthStore.getState().setUser(profile);
          return { sessionId: data.session?.id };
        }
      }
      throw new Error('Failed to create user profile');
    } catch (error) {
      console.error('Signup error:', error);
      throw error instanceof Error ? error : new Error('Failed to sign up');
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  async signIn(email: string, password: string) {
    try {
      useAuthStore.getState().setLoading(true);
      useAuthStore.getState().setError(null);

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (error) throw error;

      if (data.user) {
        const profile = await UserModel.getCurrentUser();
        if (profile) {
          useAuthStore.getState().setUser(profile);
          return { sessionId: data.session?.id };
        }
      }
      throw new Error('Failed to load user profile');
    } catch (error) {
      console.error('Login error:', error);
      throw error instanceof Error ? error : new Error('Invalid email or password');
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  async signOut() {
    try {
      useAuthStore.getState().setLoading(true);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      useAuthStore.getState().reset();
    } catch (error) {
      console.error('Signout error:', error);
      throw error instanceof Error ? error : new Error('Failed to sign out');
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  }
};