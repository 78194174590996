import React from 'react';
import { Link } from 'react-router-dom';
import { Github, Twitter, Linkedin, MessageSquare, Users, Target } from 'lucide-react';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-dark-400 border-t border-primary-500/10">
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Main footer content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {/* Brand section */}
          <div className="space-y-4">
            <Link to="/" className="flex items-center gap-2">
              <img src="/logo.svg" alt="InsightSwap" className="h-8 w-auto" />
              <h1 className="text-2xl text-white">
                <span className="font-normal">Insight</span>
                <span className="font-bold">Swap</span>
              </h1>
            </Link>
            <p className="text-gray-400 text-sm">
              Connect with real customers and get actionable feedback to improve your product.
            </p>
            <div className="flex items-center gap-4">
              <a
                href="https://twitter.com/insightswap"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 text-gray-400 hover:text-primary-400 transition-colors"
              >
                <Twitter className="w-5 h-5" />
              </a>
              <a
                href="https://github.com/insightswap"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 text-gray-400 hover:text-primary-400 transition-colors"
              >
                <Github className="w-5 h-5" />
              </a>
              <a
                href="https://linkedin.com/company/insightswap"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 text-gray-400 hover:text-primary-400 transition-colors"
              >
                <Linkedin className="w-5 h-5" />
              </a>
            </div>
          </div>

          {/* Product section */}
          <div>
            <h3 className="font-semibold mb-4">Product</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/features" className="text-gray-400 hover:text-gray-300 transition-colors hover:scale-105 inline-block">
                  Features
                </Link>
              </li>
              <li>
                <Link to="/projects" className="text-gray-400 hover:text-gray-300 transition-colors hover:scale-105 inline-block">
                  Projects
                </Link>
              </li>
              <li>
                <Link to="/schedule" className="text-gray-400 hover:text-gray-300 transition-colors hover:scale-105 inline-block">
                  Schedule Call
                </Link>
              </li>
              <li>
                <Link to="/pricing/tokens" className="text-gray-400 hover:text-gray-300 transition-colors hover:scale-105 inline-block">
                  Pricing
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources section */}
          <div>
            <h3 className="font-semibold mb-4">Resources</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/docs" className="text-gray-400 hover:text-gray-300 transition-colors hover:scale-105 inline-block">
                  Documentation
                </Link>
              </li>
              <li>
                <Link to="/changelog" className="text-gray-400 hover:text-gray-300 transition-colors hover:scale-105 inline-block">
                  Changelog
                </Link>
              </li>
            </ul>
          </div>

          {/* Company section */}
          <div>
            <h3 className="font-semibold mb-4">Company</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/about" className="text-gray-400 hover:text-gray-300 transition-colors hover:scale-105 inline-block">
                  About
                </Link>
              </li>
              <li>
                <Link to="/careers" className="text-gray-400 hover:text-gray-300 transition-colors hover:scale-105 inline-block">
                  Careers
                </Link>
              </li>
              <li>
                <Link to="/legal/privacy" className="text-gray-400 hover:text-gray-300 transition-colors hover:scale-105 inline-block">
                  Privacy
                </Link>
              </li>
              <li>
                <Link to="/legal/terms" className="text-gray-400 hover:text-gray-300 transition-colors hover:scale-105 inline-block">
                  Terms
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Stats section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 py-8 border-y border-primary-500/10">
          <div className="flex items-center gap-3">
            <div className="w-12 h-12 rounded-lg bg-primary-500/10 flex items-center justify-center">
              <MessageSquare className="w-6 h-6 text-primary-400" />
            </div>
            <div>
              <p className="text-2xl font-bold">2,000+</p>
              <p className="text-sm text-gray-400">Feedback Sessions</p>
            </div>
          </div>
          
          <div className="flex items-center gap-3">
            <div className="w-12 h-12 rounded-lg bg-secondary-500/10 flex items-center justify-center">
              <Users className="w-6 h-6 text-secondary-400" />
            </div>
            <div>
              <p className="text-2xl font-bold">500+</p>
              <p className="text-sm text-gray-400">Active Users</p>
            </div>
          </div>
          
          <div className="flex items-center gap-3">
            <div className="w-12 h-12 rounded-lg bg-token-500/10 flex items-center justify-center">
              <Target className="w-6 h-6 text-token-400" />
            </div>
            <div>
              <p className="text-2xl font-bold">95%</p>
              <p className="text-sm text-gray-400">Satisfaction Rate</p>
            </div>
          </div>
        </div>

        {/* Bottom section */}
        <div className="pt-8 flex flex-col md:flex-row justify-between items-center gap-4">
          <p className="text-sm text-gray-400">
            © {currentYear} InsightSwap. All rights reserved.
          </p>
          <div className="flex items-center gap-6">
            <Link to="/legal/privacy" className="text-sm text-gray-400 hover:text-gray-300">
              Privacy Policy
            </Link>
            <Link to="/legal/terms" className="text-sm text-gray-400 hover:text-gray-300">
              Terms of Service
            </Link>
            <Link to="/legal/cookie-policy" className="text-sm text-gray-400 hover:text-gray-300">
              Cookie Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}