import React from 'react';
import { Calendar as CalendarIcon } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../lib/supabase';
import { useNotifications } from '../../contexts/NotificationContext';
import { LoadingState } from '../ui/LoadingState';
import { EmptyState } from '../ui/EmptyState';
import { ErrorState } from '../ui/ErrorState';
import { CallCard } from './CallCard';
import type { Booking } from '../../lib/models/booking';

export function UpcomingCallsSection() {
  const { user } = useAuth();
  const { addNotification } = useNotifications();
  const [bookings, setBookings] = React.useState<Booking[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [actionInProgress, setActionInProgress] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (user?.id) {
      loadBookings(user.id);
    }
  }, [user]);

  const loadBookings = async (userId: string) => {
    try {
      setIsLoading(true);
      setError(null);
      
      const { data, error } = await supabase
        .from('bookings')
        .select(`
          *,
          provider:profiles!provider_id(*),
          customer:profiles!customer_id(*),
          time_slot:time_slots(*)
        `)
        .or(`customer_id.eq.${userId},provider_id.eq.${userId}`)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Filter out bookings with missing time slots
      const validBookings = (data || []).filter(booking => booking.time_slot);
      setBookings(validBookings);
    } catch (err) {
      console.error('Error loading bookings:', err);
      setError('Failed to load upcoming calls');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAction = async (bookingId: string, action: 'accept' | 'reject') => {
    if (!user) return;
    
    setActionInProgress(bookingId);
    const newStatus = action === 'accept' ? 'confirmed' : 'cancelled';
    
    try {
      const { error } = await supabase
        .from('bookings')
        .update({ 
          status: newStatus,
          updated_at: new Date().toISOString()
        })
        .eq('id', bookingId);

      if (error) throw error;
      
      await loadBookings(user.id);
      addNotification(
        'success',
        `Call request ${action === 'accept' ? 'accepted' : 'rejected'} successfully`
      );
    } catch (err) {
      console.error(`Error ${action}ing booking:`, err);
      addNotification('error', `Failed to ${action} call request`);
    } finally {
      setActionInProgress(null);
    }
  };

  if (isLoading) {
    return <LoadingState message="Loading upcoming calls..." />;
  }

  if (error) {
    return (
      <ErrorState 
        message={error}
        action={{
          label: 'Try Again',
          onClick: () => user && loadBookings(user.id)
        }}
      />
    );
  }

  if (bookings.length === 0) {
    return (
      <EmptyState
        icon={CalendarIcon}
        title="No upcoming calls"
        description="When you schedule or receive call requests, they'll appear here."
        action={{
          label: 'Schedule a Call',
          onClick: () => window.location.href = '/schedule'
        }}
      />
    );
  }

  return (
    <div className="space-y-4">
      {bookings.map((booking) => (
        <CallCard
          key={booking.id}
          booking={booking}
          onAction={
            booking.status === 'pending' && booking.provider_id === user?.id
              ? (action) => handleAction(booking.id, action)
              : undefined
          }
          isActionInProgress={actionInProgress === booking.id}
        />
      ))}
    </div>
  );
}