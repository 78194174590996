import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Users, Target, Shield, Globe } from 'lucide-react';

export function AboutPage() {
  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold mb-4">About InsightSwap</h1>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto">
              Connecting entrepreneurs with real customers for meaningful feedback and insights
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-12 mb-16">
            <div>
              <h2 className="text-2xl font-bold mb-6">Our Mission</h2>
              <p className="text-gray-300 leading-relaxed mb-6">
                At InsightSwap, we believe that the best products are built with direct customer feedback. 
                Our mission is to make it easy for entrepreneurs and product builders to connect with real 
                users, gather actionable insights, and build products people love.
              </p>
              <p className="text-gray-300 leading-relaxed">
                Through our token-based system, we ensure high-quality feedback by incentivizing meaningful 
                interactions and fostering a community of engaged users who are passionate about helping 
                others succeed.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="glass-card rounded-lg p-6 text-center">
                <div className="w-12 h-12 bg-primary-500/10 rounded-full flex items-center justify-center mx-auto mb-4">
                  <Users className="w-6 h-6 text-primary-400" />
                </div>
                <h3 className="text-xl font-bold mb-2">2,000+</h3>
                <p className="text-gray-400">Active Users</p>
              </div>
              <div className="glass-card rounded-lg p-6 text-center">
                <div className="w-12 h-12 bg-secondary-500/10 rounded-full flex items-center justify-center mx-auto mb-4">
                  <Target className="w-6 h-6 text-secondary-400" />
                </div>
                <h3 className="text-xl font-bold mb-2">10,000+</h3>
                <p className="text-gray-400">Feedback Sessions</p>
              </div>
              <div className="glass-card rounded-lg p-6 text-center">
                <div className="w-12 h-12 bg-token-500/10 rounded-full flex items-center justify-center mx-auto mb-4">
                  <Globe className="w-6 h-6 text-token-400" />
                </div>
                <h3 className="text-xl font-bold mb-2">50+</h3>
                <p className="text-gray-400">Countries</p>
              </div>
              <div className="glass-card rounded-lg p-6 text-center">
                <div className="w-12 h-12 bg-primary-500/10 rounded-full flex items-center justify-center mx-auto mb-4">
                  <Shield className="w-6 h-6 text-primary-400" />
                </div>
                <h3 className="text-xl font-bold mb-2">95%</h3>
                <p className="text-gray-400">Satisfaction Rate</p>
              </div>
            </div>
          </div>

          <div className="glass-card rounded-lg p-8 mb-16">
            <h2 className="text-2xl font-bold mb-6">Our Values</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div>
                <h3 className="text-xl font-bold mb-3">Quality First</h3>
                <p className="text-gray-400">
                  We prioritize meaningful, actionable feedback over quantity. Our token system 
                  ensures that every interaction adds value.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-3">Community Driven</h3>
                <p className="text-gray-400">
                  Our platform is built on the principle of mutual benefit. Users both give and 
                  receive feedback, creating a sustainable ecosystem.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-3">User Privacy</h3>
                <p className="text-gray-400">
                  We take confidentiality seriously. All feedback sessions are protected by our 
                  strict privacy policies and NDAs.
                </p>
              </div>
            </div>
          </div>

          <div className="text-center">
            <h2 className="text-2xl font-bold mb-6">Join Our Team</h2>
            <p className="text-gray-400 mb-8 max-w-2xl mx-auto">
              We're always looking for talented individuals who are passionate about helping 
              entrepreneurs build better products. Check out our open positions.
            </p>
            <a 
              href="/careers"
              className="btn-outline-gradient inline-flex items-center gap-2 transition-transform hover:scale-105"
            >
              View Open Positions
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}