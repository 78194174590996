import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Code, Presentation, MessageSquare, Users } from 'lucide-react';

const openings = [
  {
    title: 'Senior Full Stack Engineer',
    department: 'Engineering',
    location: 'Remote',
    type: 'Full-time',
    description: "We're looking for a senior full-stack engineer to help build and scale our platform."
  },
  {
    title: 'Product Designer',
    department: 'Design',
    location: 'Remote',
    type: 'Full-time',
    description: "Join our design team to create beautiful and intuitive user experiences."
  },
  {
    title: 'Community Manager',
    department: 'Operations',
    location: 'Remote',
    type: 'Full-time',
    description: "Help grow and nurture our community of entrepreneurs and product builders."
  },
];

const benefits = [
  {
    icon: Users,
    title: 'Remote-First Culture',
    description: 'Work from anywhere in the world with our distributed team.',
  },
  {
    icon: MessageSquare,
    title: 'Learning Budget',
    description: 'Annual budget for courses, conferences, and professional development.',
  },
  {
    icon: Code,
    title: 'Latest Technology',
    description: 'Work with modern tools and frameworks to build innovative solutions.',
  },
  {
    icon: Presentation,
    title: 'Career Growth',
    description: 'Regular 1:1s and clear progression paths for your career development.',
  },
];

export function CareersPage() {
  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold mb-4">Join Our Team</h1>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto">
              Help us build the future of product feedback and customer insights
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-12 mb-16">
            <div>
              <h2 className="text-2xl font-bold mb-6">Why InsightSwap?</h2>
              <p className="text-gray-300 leading-relaxed mb-6">
                We're on a mission to help entrepreneurs build better products through meaningful 
                customer feedback. Our platform connects product builders with real users, 
                facilitating valuable insights and iterations.
              </p>
              <p className="text-gray-300 leading-relaxed">
                As a remote-first company, we believe in giving our team the freedom to work 
                from anywhere while maintaining a strong culture of collaboration and innovation.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-6">
              {benefits.map((benefit, index) => (
                <div key={index} className="glass-card rounded-lg p-6">
                  <div className="w-12 h-12 bg-primary-500/10 rounded-full flex items-center justify-center mb-4">
                    <benefit.icon className="w-6 h-6 text-primary-400" />
                  </div>
                  <h3 className="text-lg font-bold mb-2">{benefit.title}</h3>
                  <p className="text-gray-400 text-sm">{benefit.description}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-16">
            <h2 className="text-2xl font-bold mb-8">Open Positions</h2>
            <div className="space-y-6">
              {openings.map((job, index) => (
                <div key={index} className="glass-card rounded-lg p-6 hover:border-primary-500/40 transition-all">
                  <div className="flex flex-wrap items-start justify-between gap-4">
                    <div>
                      <h3 className="text-xl font-bold mb-2">{job.title}</h3>
                      <div className="flex flex-wrap gap-4 text-sm">
                        <span className="text-primary-400">{job.department}</span>
                        <span className="text-gray-400">•</span>
                        <span className="text-gray-400">{job.location}</span>
                        <span className="text-gray-400">•</span>
                        <span className="text-gray-400">{job.type}</span>
                      </div>
                      <p className="text-gray-300 mt-4">{job.description}</p>
                    </div>
                    <a
                      href={`mailto:careers@insightswap.com?subject=Application for ${job.title}`}
                      className="btn-outline-gradient transition-transform hover:scale-105"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="glass-card rounded-lg p-8 text-center">
            <h2 className="text-2xl font-bold mb-4">Don't see the right role?</h2>
            <p className="text-gray-400 mb-6">
              We're always looking for talented individuals. Send us your resume and we'll keep you in mind for future openings.
            </p>
            <a
              href="mailto:careers@insightswap.com?subject=General Application"
              className="btn-outline-gradient inline-flex items-center gap-2 transition-transform hover:scale-105"
            >
              Send General Application
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}