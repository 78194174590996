import React from 'react';
import { Lock, Shield, Eye } from 'lucide-react';

interface ConfidentialityTermsProps {
  onAccept: () => void;
  onCancel: () => void;
}

export function ConfidentialityTerms({ onAccept, onCancel }: ConfidentialityTermsProps) {
  return (
    <div className="glass-card rounded-lg p-6 space-y-6">
      <div className="flex items-center gap-3 text-xl font-semibold">
        <Lock className="w-6 h-6 text-primary-400" />
        <h3>Confidentiality Agreement</h3>
      </div>

      <div className="space-y-4 text-gray-400">
        <p>
          By joining InsightSwap, you agree to maintain the confidentiality of all information 
          shared during feedback sessions. This includes:
        </p>

        <div className="space-y-3">
          <div className="flex items-start gap-3">
            <Shield className="w-5 h-5 text-primary-400 mt-1 flex-shrink-0" />
            <p>Not disclosing any confidential information, including product details, business strategies, 
            or intellectual property shared during sessions.</p>
          </div>

          <div className="flex items-start gap-3">
            <Shield className="w-5 h-5 text-primary-400 mt-1 flex-shrink-0" />
            <p>Not using confidential information for personal gain or competitive advantage.</p>
          </div>

          <div className="flex items-start gap-3">
            <Shield className="w-5 h-5 text-primary-400 mt-1 flex-shrink-0" />
            <p>Not recording or sharing screenshots of feedback sessions without explicit permission.</p>
          </div>

          <div className="flex items-start gap-3">
            <Eye className="w-5 h-5 text-primary-400 mt-1 flex-shrink-0" />
            <p>Understanding that violation of these terms may result in account suspension and 
            possible legal action.</p>
          </div>
        </div>

        <div className="bg-primary-500/10 border border-primary-500/20 rounded-lg p-4 text-sm">
          <p>
            This agreement is legally binding and helps ensure a safe environment for sharing 
            feedback and ideas. For full details, please review our{' '}
            <a href="/terms" className="text-primary-400 hover:text-primary-300">
              Terms of Service
            </a>
            .
          </p>
        </div>
      </div>

      <div className="flex justify-end gap-3 pt-4">
        <button
          onClick={onCancel}
          className="px-4 py-2 text-gray-300 border border-primary-500/20 rounded-lg hover:bg-primary-500/5"
        >
          Cancel
        </button>
        <button
          onClick={onAccept}
          className="btn-outline-gradient"
        >
          Accept & Continue
        </button>
      </div>
    </div>
  );
}