import React from 'react';
import { X, Send, Loader2, Minimize2, Maximize2 } from 'lucide-react';
import { format } from 'date-fns';
import { supabase } from '../../lib/supabase';
import { UserInitials } from '../UserInitials';
import { useAuth } from '../../contexts/AuthContext';
import type { User } from '../../lib/models/user';
import type { RealtimeChannel } from '@supabase/supabase-js';

interface Message {
  id: string;
  content: string;
  sender_id: string;
  created_at: string;
  sender?: {
    name: string;
    avatar: string;
  };
  isPending?: boolean;
}

interface ChatPopupProps {
  bookingId: string;
  otherUser: User;
  onClose: () => void;
}

export function ChatPopup({ bookingId, otherUser, onClose }: ChatPopupProps) {
  const { user } = useAuth();
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [newMessage, setNewMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSending, setIsSending] = React.useState(false);
  const [isMinimized, setIsMinimized] = React.useState(false);
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const [error, setError] = React.useState<string | null>(null);
  const channelRef = React.useRef<RealtimeChannel | null>(null);
  const pendingMessagesRef = React.useRef<Set<string>>(new Set());

  React.useEffect(() => {
    loadMessages();
    const channel = subscribeToMessages();
    channelRef.current = channel;

    return () => {
      if (channelRef.current) {
        channelRef.current.unsubscribe();
      }
    };
  }, [bookingId]);

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const loadMessages = async () => {
    try {
      const { data, error } = await supabase
        .from('messages')
        .select(`
          *,
          sender:profiles(name, avatar)
        `)
        .eq('booking_id', bookingId)
        .order('created_at', { ascending: true });

      if (error) throw error;
      setMessages(data || []);
    } catch (err) {
      console.error('Error loading messages:', err);
      setError('Failed to load messages');
    } finally {
      setIsLoading(false);
    }
  };

  const subscribeToMessages = () => {
    return supabase
      .channel(`chat:${bookingId}`)
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'messages',
          filter: `booking_id=eq.${bookingId}`
        },
        async (payload) => {
          const { data: sender } = await supabase
            .from('profiles')
            .select('name, avatar')
            .eq('id', payload.new.sender_id)
            .single();

          const newMessage = {
            ...payload.new,
            sender
          };

          // Check if this is a pending message being confirmed
          const isPendingMessage = pendingMessagesRef.current.has(payload.new.content);
          if (isPendingMessage) {
            pendingMessagesRef.current.delete(payload.new.content);
            setMessages(prev => prev.map(msg => 
              msg.isPending && msg.content === payload.new.content
                ? { ...newMessage, isPending: false }
                : msg
            ));
          } else {
            setMessages(prev => [...prev, newMessage]);
          }
        }
      )
      .subscribe();
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !newMessage.trim()) return;

    const messageContent = newMessage.trim();
    setNewMessage('');

    // Track pending message
    pendingMessagesRef.current.add(messageContent);

    // Add optimistic message
    const optimisticMessage: Message = {
      id: crypto.randomUUID(),
      content: messageContent,
      sender_id: user.id,
      created_at: new Date().toISOString(),
      sender: {
        name: user.name,
        avatar: user.avatar || '',
      },
      isPending: true
    };

    setMessages(prev => [...prev, optimisticMessage]);

    try {
      setIsSending(true);
      const { error } = await supabase
        .from('messages')
        .insert([{
          booking_id: bookingId,
          sender_id: user.id,
          content: messageContent
        }]);

      if (error) throw error;
    } catch (err) {
      console.error('Error sending message:', err);
      setError('Failed to send message');
      // Remove failed message from pending set
      pendingMessagesRef.current.delete(messageContent);
      // Remove failed optimistic message
      setMessages(prev => prev.filter(msg => msg.id !== optimisticMessage.id));
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="fixed bottom-4 right-4 w-96 glass-card rounded-lg shadow-xl transition-all duration-300 z-[9999]" style={{ height: isMinimized ? '56px' : '500px' }}>
      {/* Chat header */}
      <div 
        className="p-4 border-b border-primary-500/20 flex items-center justify-between cursor-pointer bg-dark-200 rounded-t-lg"
        onClick={() => setIsMinimized(!isMinimized)}
      >
        <div className="flex items-center gap-3">
          {otherUser.avatar ? (
            <img
              src={otherUser.avatar}
              alt={otherUser.name}
              className="w-10 h-10 rounded-full object-cover"
            />
          ) : (
            <UserInitials name={otherUser.name} size="sm" />
          )}
          <div>
            <h3 className="font-medium">{otherUser.name}</h3>
            <p className="text-sm text-gray-400">Chat about your upcoming call</p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {isMinimized ? (
            <Maximize2 className="w-5 h-5 text-gray-400" />
          ) : (
            <Minimize2 className="w-5 h-5 text-gray-400" />
          )}
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className="p-1 hover:bg-primary-500/10 rounded-lg transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
      </div>

      {!isMinimized && (
        <>
          {/* Messages */}
          <div className="h-[360px] overflow-y-auto p-4 space-y-4 bg-dark-300">
            {isLoading ? (
              <div className="flex items-center justify-center h-full">
                <Loader2 className="w-6 h-6 animate-spin text-primary-400" />
              </div>
            ) : messages.length === 0 ? (
              <div className="text-center text-gray-400 py-8">
                No messages yet. Start the conversation!
              </div>
            ) : (
              messages.map((message) => {
                const isOwnMessage = message.sender_id === user?.id;

                return (
                  <div
                    key={message.id}
                    className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'}`}
                  >
                    <div className={`flex items-start gap-2 max-w-[80%] ${isOwnMessage ? 'flex-row-reverse' : ''}`}>
                      {!isOwnMessage && (
                        message.sender?.avatar ? (
                          <img
                            src={message.sender.avatar}
                            alt={message.sender.name}
                            className="w-8 h-8 rounded-full object-cover"
                          />
                        ) : (
                          <UserInitials name={message.sender?.name || ''} size="sm" />
                        )
                      )}
                      <div>
                        <div
                          className={`rounded-lg p-3 ${
                            isOwnMessage
                              ? `bg-primary-500/20 text-primary-100 ${message.isPending ? 'opacity-50' : ''}`
                              : 'bg-dark-200 text-gray-100'
                          }`}
                        >
                          {message.content}
                        </div>
                        <div className={`text-xs text-gray-400 mt-1 ${isOwnMessage ? 'text-right' : ''}`}>
                          {message.isPending ? (
                            'Sending...'
                          ) : (
                            format(new Date(message.created_at), 'p')
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* Message input */}
          <form onSubmit={handleSendMessage} className="p-4 border-t border-primary-500/20 bg-dark-200 rounded-b-lg">
            {error && (
              <div className="mb-2 text-sm text-red-400">
                {error}
              </div>
            )}
            <div className="flex gap-2">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
              />
              <button
                type="submit"
                disabled={isSending || !newMessage.trim()}
                className="btn-outline-gradient disabled:opacity-50"
              >
                {isSending ? (
                  <Loader2 className="w-5 h-5 animate-spin" />
                ) : (
                  <Send className="w-5 h-5" />
                )}
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}