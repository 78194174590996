import React from 'react';
import { Search, X } from 'lucide-react';
import * as Slider from '@radix-ui/react-slider';
import { expertise } from '../data/expertise';

interface SearchFiltersProps {
  onSearch: (query: string) => void;
  onFilterChange: (filters: any) => void;
}

interface Tag {
  label: string;
  value: string;
}

export function SearchFilters({ onSearch, onFilterChange }: SearchFiltersProps) {
  const [selectedTags, setSelectedTags] = React.useState<Tag[]>([]);
  const [priceRange, setPriceRange] = React.useState([0, 200]);
  
  const handleTagSelect = (tag: Tag) => {
    if (!selectedTags.find(t => t.label === tag.label)) {
      const newTags = [...selectedTags, tag];
      setSelectedTags(newTags);
      onFilterChange({ expertise: newTags.map(t => t.label) });
    }
  };

  const handleTagRemove = (tagToRemove: Tag) => {
    const newTags = selectedTags.filter(tag => tag.label !== tagToRemove.label);
    setSelectedTags(newTags);
    onFilterChange({ expertise: newTags.map(t => t.label) });
  };

  const handlePriceChange = (values: number[]) => {
    setPriceRange(values);
    onFilterChange({ priceRange: values.join('-') });
  };

  return (
    <div className="glass-card p-4 rounded-lg mb-6">
      <div className="space-y-4">
        <div className="flex-1 relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-primary-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Search by expertise, industry, or keyword..."
            className="w-full pl-10 pr-4 py-2 bg-dark-400 border border-primary-500/20 rounded-lg text-gray-200 placeholder-gray-400 focus:outline-none focus:border-primary-500"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
        
        <div className="flex flex-wrap gap-2">
          {selectedTags.map((tag) => (
            <span
              key={tag.value}
              className="inline-flex items-center gap-1 px-3 py-1 bg-primary-500/10 text-primary-300 rounded-full text-sm border border-primary-500/20"
            >
              {tag.label}
              <button
                onClick={() => handleTagRemove(tag)}
                className="hover:text-primary-400"
              >
                <X className="w-3 h-3" />
              </button>
            </span>
          ))}
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Expertise
            </label>
            <div className="flex flex-wrap gap-2">
              {expertise.map((tag) => (
                <button
                  key={tag.label}
                  onClick={() => handleTagSelect(tag)}
                  disabled={selectedTags.some(t => t.label === tag.label)}
                  className={`px-3 py-1 rounded-full text-sm border transition-colors ${
                    selectedTags.some(t => t.label === tag.label)
                      ? 'bg-primary-500/20 border-primary-500/40 text-primary-300 cursor-not-allowed'
                      : 'border-primary-500/20 text-gray-400 hover:border-primary-500/40 hover:text-primary-300'
                  }`}
                >
                  {tag.label}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Price Range ($/hour)
            </label>
            <div className="px-2 pt-2">
              <Slider.Root
                className="relative flex items-center select-none touch-none w-full h-5"
                value={priceRange}
                onValueChange={handlePriceChange}
                min={0}
                max={200}
                step={10}
              >
                <Slider.Track className="bg-dark-400 relative grow rounded-full h-[3px]">
                  <Slider.Range className="absolute bg-primary-400 rounded-full h-full" />
                </Slider.Track>
                <Slider.Thumb
                  className="block w-5 h-5 bg-white rounded-full border-2 border-primary-400 hover:bg-gray-50 focus:outline-none"
                  aria-label="Price range minimum"
                />
                <Slider.Thumb
                  className="block w-5 h-5 bg-white rounded-full border-2 border-primary-400 hover:bg-gray-50 focus:outline-none"
                  aria-label="Price range maximum"
                />
              </Slider.Root>
              <div className="flex justify-between mt-2 text-sm text-gray-400">
                <span>${priceRange[0]}</span>
                <span>${priceRange[1]}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}