import React from 'react';

interface UserInitialsProps {
  name: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

export function UserInitials({ name, size = 'md', className = '' }: UserInitialsProps) {
  const initials = name
    .split(' ')
    .map(part => part[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);

  const sizeClasses = {
    sm: 'w-8 h-8 text-sm',
    md: 'w-12 h-12 text-lg',
    lg: 'w-24 h-24 text-2xl'
  };

  return (
    <div 
      className={`rounded-full bg-primary-500/20 text-primary-300 font-medium flex items-center justify-center ${sizeClasses[size]} ${className}`}
    >
      {initials}
    </div>
  );
}