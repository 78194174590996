import { useState, useCallback } from 'react';
import { supabase } from '../lib/supabase';
import type { User } from '../lib/models/user';

export function useUsers() {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchUsers = useCallback(async (params?: {
    expertise?: string[];
    priceRange?: [number, number];
    search?: string;
  }) => {
    try {
      setIsLoading(true);
      setError(null);
      
      let query = supabase
        .from('profiles')
        .select(`
          *
        `);

      if (params?.expertise?.length) {
        query = query.overlaps('expertise', params.expertise);
      }

      if (params?.priceRange) {
        const [min, max] = params.priceRange;
        query = query
          .gte('hourly_rate', min)
          .lte('hourly_rate', max);
      }

      if (params?.search) {
        const searchTerm = `%${params.search}%`;
        query = query.or(
          `name.ilike.${searchTerm},bio.ilike.${searchTerm},title.ilike.${searchTerm},company.ilike.${searchTerm}`
        );
      }

      const { data, error: fetchError } = await query;

      if (fetchError) throw fetchError;
      setUsers(data || []);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to load users. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    users,
    isLoading,
    error,
    fetchUsers
  };
}