import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Header } from '../components/Header';
import { useAuth } from '../contexts/AuthContext';
import { LoginForm, RegisterForm } from '../components/AuthForms';

export function AuthPage() {
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();
  const [isSignUp, setIsSignUp] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('signup') === 'true';
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (user) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-16">
        <div className="max-w-md mx-auto px-4 py-16">
          <div className="glass-card rounded-lg p-8">
            <h1 className="text-2xl font-bold text-center mb-8">
              {isSignUp ? 'Create an Account' : 'Welcome Back'}
            </h1>

            {isSignUp ? <RegisterForm /> : <LoginForm />}

            <div className="mt-6 text-center">
              <button
                onClick={() => setIsSignUp(!isSignUp)}
                className="text-primary-400 hover:text-primary-300 text-sm"
              >
                {isSignUp
                  ? 'Already have an account? Sign in'
                  : "Don't have an account? Sign up"}
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}