import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LayoutDashboard, UserCircle, Settings, Calendar, LogOut, MessageSquare, Lightbulb } from 'lucide-react';
import { NotificationsPopover } from '../NotificationsPopover';
import { useAuth } from '../../contexts/AuthContext';
import { UpcomingCallsSection } from '../dashboard/UpcomingCallsSection';

interface DashboardLayoutProps {
  children: React.ReactNode;
}

export function DashboardLayout({ children }: DashboardLayoutProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  const navItems = [
    { label: 'Dashboard', icon: LayoutDashboard, path: '/dashboard' },
    { label: 'Schedule Call', icon: Calendar, path: '/schedule' },
    { label: 'Feedback Requests', icon: MessageSquare, path: '/projects' },
    { label: 'Feature Suggestions', icon: Lightbulb, path: '/features' },
    { label: 'Profile', icon: UserCircle, path: '/profile' },
    { label: 'Settings', icon: Settings, path: '/settings' },
  ];

  return (
    <div className="min-h-screen bg-dark-300">
      {/* Sidebar */}
      <aside className="w-64 fixed left-0 top-0 bottom-0 bg-dark-200/50 backdrop-blur-sm border-r border-primary-500/20 z-40 flex flex-col">
        {/* Fixed header */}
        <div className="p-4 border-b border-primary-500/20">
          <Link to="/" className="flex items-center gap-2">
            <img src="/logo.svg" alt="InsightSwap" className="h-8 w-auto" />
            <h1 className="text-2xl font-bold text-white">InsightSwap</h1>
          </Link>
        </div>
        
        {/* Scrollable content */}
        <div className="flex-1 overflow-y-auto">
          <nav className="p-4">
            <div className="space-y-1">
              {navItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`
                    flex items-center gap-3 px-4 py-2.5 rounded-lg text-sm font-medium
                    ${location.pathname === item.path
                      ? 'bg-primary-500/20 text-primary-300'
                      : 'text-gray-400 hover:bg-primary-500/10 hover:text-gray-200'
                    }
                    transition-colors
                  `}
                >
                  <item.icon className="w-5 h-5" />
                  {item.label}
                </Link>
              ))}
            </div>
            
            <div className="pt-4 border-t border-primary-500/20 mt-4 space-y-4">
              <h3 className="px-4 text-xs uppercase tracking-wider font-semibold text-gray-400">
                Upcoming Calls
              </h3>
              <div className="space-y-2 pr-2">
                <UpcomingCallsSection />
              </div>
            </div>
          </nav>
        </div>

        {/* Fixed footer */}
        <div className="p-4 border-t border-primary-500/20">
          <button
            onClick={handleSignOut}
            className="w-full flex items-center gap-3 px-4 py-2 rounded-lg text-sm text-gray-400 hover:bg-red-500/10 hover:text-red-400 transition-colors"
          >
            <LogOut className="w-5 h-5" />
            Sign Out
          </button>
        </div>
      </aside>
      
      {/* Top bar */}
      <div className="fixed top-0 left-64 right-0 h-16 bg-dark-200/50 backdrop-blur-sm border-b border-primary-500/20 z-50 flex items-center justify-end px-6">
        <NotificationsPopover />
      </div>

      {/* Main content */}
      <div className="pl-64 pt-16 min-h-screen">
        {children}
      </div>
    </div>
  );
}