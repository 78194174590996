import React from 'react';
import { DailyProvider } from '@daily-co/daily-react';
import { VideoControls } from './video/VideoControls';
import { VideoGrid } from './video/VideoGrid';

interface VideoCallProps {
  roomUrl: string;
  onLeave: () => void;
}

export function VideoCall({ roomUrl, onLeave }: VideoCallProps) {
  return (
    <DailyProvider
      url={roomUrl}
      onLeave={onLeave}
    >
      <div className="relative w-full h-full min-h-[600px] bg-dark-400 rounded-lg overflow-hidden">
        <VideoGrid />
        <VideoControls />
      </div>
    </DailyProvider>
  );
}