import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Shield, Lock, Eye, FileText } from 'lucide-react';

export function PrivacyPage() {
  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-24 pb-16">
        <div className="max-w-3xl mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
            <p className="text-xl text-gray-400">
              Last updated: March 25, 2024
            </p>
          </div>

          <div className="space-y-12">
            <section>
              <div className="flex items-center gap-3 mb-4">
                <Shield className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">Introduction</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <p>
                  At InsightSwap, we take your privacy seriously. This Privacy Policy explains how we collect,
                  use, disclose, and safeguard your information when you use our platform.
                </p>
                <p>
                  By using InsightSwap, you agree to the collection and use of information in accordance with
                  this policy.
                </p>
              </div>
            </section>

            <section>
              <div className="flex items-center gap-3 mb-4">
                <FileText className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">Information We Collect</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <h3 className="text-xl font-semibold mb-2">Personal Information</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Name and email address</li>
                  <li>Profile information (title, company, bio)</li>
                  <li>Payment information</li>
                  <li>Communication preferences</li>
                </ul>

                <h3 className="text-xl font-semibold mb-2">Usage Information</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Session data and interactions</li>
                  <li>Device and browser information</li>
                  <li>IP address and location data</li>
                  <li>Feature usage patterns</li>
                </ul>
              </div>
            </section>

            <section>
              <div className="flex items-center gap-3 mb-4">
                <Lock className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">How We Protect Your Data</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <p>
                  We implement a variety of security measures to maintain the safety of your personal
                  information:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>End-to-end encryption for all sensitive data</li>
                  <li>Regular security audits and penetration testing</li>
                  <li>Strict access controls and authentication</li>
                  <li>Data backup and disaster recovery protocols</li>
                </ul>
              </div>
            </section>

            <section>
              <div className="flex items-center gap-3 mb-4">
                <Eye className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">Your Privacy Rights</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <p>You have the right to:</p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Access your personal information</li>
                  <li>Correct inaccurate data</li>
                  <li>Request deletion of your data</li>
                  <li>Opt-out of marketing communications</li>
                  <li>Export your data in a portable format</li>
                </ul>
              </div>
            </section>
          </div>

          <div className="mt-12 p-6 glass-card rounded-lg">
            <h2 className="text-xl font-bold mb-4">Questions About Privacy?</h2>
            <p className="text-gray-400 mb-6">
              If you have any questions about this Privacy Policy or our practices, please contact us.
            </p>
            <a
              href="mailto:privacy@insightswap.com"
              className="btn-outline-gradient inline-flex items-center gap-2"
            >
              Contact Privacy Team
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}