import React from 'react';
import { Header } from '../components/Header';
import { ProfileSection } from '../components/dashboard/ProfileSection';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { DashboardLayout } from '../components/layouts/DashboardLayout';

export function ProfilePage() {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/auth" replace />;
  }

  return (
    <DashboardLayout>
      <main className="pt-20 pb-16">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-3xl font-bold mb-8">Profile</h1>
          <ProfileSection />
        </div>
      </main>
    </DashboardLayout>
  );
}