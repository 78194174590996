import React, { useState, useEffect } from 'react';
import { Header } from '../components/Header';
import { Plus, ExternalLink, MessageSquare, Coins, Laptop, Target } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { useNotifications } from '../contexts/NotificationContext';
import { format } from 'date-fns';
import { UserInitials } from '../components/UserInitials';

interface FeedbackRequest {
  id: string;
  title: string;
  description: string;
  type: 'web' | 'mobile' | 'desktop' | 'other';
  demo_url?: string;
  tokens_offered: number;
  created_at: string;
  user: {
    id: string;
    name: string;
    avatar?: string;
  };
  feedback_count: number;
}

export function FeedbackRequestsPage() {
  const { user } = useAuth();
  const { addNotification } = useNotifications();
  const [requests, setRequests] = useState<FeedbackRequest[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showNewForm, setShowNewForm] = useState(false);
  const [newRequest, setNewRequest] = useState({
    title: '',
    description: '',
    type: 'web',
    demo_url: '',
    tokens_offered: 1
  });

  useEffect(() => {
    loadRequests();
  }, []);

  const loadRequests = async () => {
    try {
      const { data, error } = await supabase
        .from('feedback_requests')
        .select(`
          *,
          user:profiles(id, name, avatar),
          feedback:feedback(count)
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setRequests(data || []);
    } catch (err) {
      console.error('Error loading requests:', err);
      addNotification('error', 'Failed to load feedback requests');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;
    setIsLoading(true);
    
    if (user.tokens < newRequest.tokens_offered) {
      addNotification('error', `You need at least ${newRequest.tokens_offered} tokens to create this request`);
      setIsLoading(false);
      return;
    }

    try {
      // First create the feedback request
      const { data: request, error: requestError } = await supabase
        .from('feedback_requests')
        .insert([{
          ...newRequest,
          user_id: user.id,
          tokens_offered: newRequest.tokens_offered
        }])
        .select()
        .single();

      if (requestError) throw requestError;

      addNotification('success', 'Feedback request posted successfully');
      setNewRequest({
        title: '',
        description: '',
        type: 'web',
        demo_url: '',
        tokens_offered: 1
      });
      setShowNewForm(false);
      loadRequests();

    } catch (err) {
      console.error('Error submitting request:', err);
      addNotification('error', 'Failed to post feedback request');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-8">
            <div>
              <h2 className="text-2xl font-bold mb-2">Feedback Requests</h2>
              <p className="text-gray-400">Share your project and get valuable feedback from the community</p>
            </div>
            {!showNewForm && (
              <button 
                onClick={() => setShowNewForm(true)}
                className="btn-outline-gradient inline-flex items-center gap-2"
              >
                <Plus className="w-4 h-4" />
                Request Feedback
              </button>
            )}
          </div>

          {showNewForm && (
            <div className="glass-card rounded-lg p-6 mb-8">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Project Title
                  </label>
                  <input
                    type="text"
                    value={newRequest.title}
                    onChange={e => setNewRequest(prev => ({ ...prev, title: e.target.value }))}
                    className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    value={newRequest.description}
                    onChange={e => setNewRequest(prev => ({ ...prev, description: e.target.value }))}
                    rows={4}
                    className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
                    placeholder="Describe what you're building and what kind of feedback you're looking for..."
                    required
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Project Type
                    </label>
                    <select
                      value={newRequest.type}
                      onChange={e => setNewRequest(prev => ({ ...prev, type: e.target.value as any }))}
                      className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
                    >
                      <option value="web">Web Application</option>
                      <option value="mobile">Mobile App</option>
                      <option value="desktop">Desktop App</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Demo URL (optional)
                    </label>
                    <input
                      type="url"
                      value={newRequest.demo_url}
                      onChange={e => setNewRequest(prev => ({ ...prev, demo_url: e.target.value }))}
                      className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
                      placeholder="https://your-demo-url.com"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Tokens Offered
                  </label>
                  <select
                    value={newRequest.tokens_offered}
                    onChange={e => setNewRequest(prev => ({ ...prev, tokens_offered: Number(e.target.value) }))}
                    className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
                  >
                    <option value={1}>1 Token</option>
                    <option value={2}>2 Tokens</option>
                    <option value={3}>3 Tokens</option>
                  </select>
                  <p className="text-xs text-gray-400 mt-1">
                    Higher token rewards typically attract more detailed feedback
                  </p>
                </div>

                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => setShowNewForm(false)}
                    className="px-4 py-2 text-gray-300 border border-primary-500/20 rounded-lg hover:bg-primary-500/5"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn-outline-gradient disabled:opacity-50"
                  >
                    {isLoading ? 'Posting...' : 'Post Request'}
                  </button>
                </div>
              </form>
            </div>
          )}

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {requests.map(request => (
              <div key={request.id} className="glass-card rounded-lg p-6">
                <div className="flex items-center gap-4 mb-4">
                  <div className="w-12 h-12 rounded-full bg-primary-500/10 flex items-center justify-center">
                    <Target className="w-6 h-6 text-primary-400" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg">{request.title}</h3>
                    <p className="text-sm text-gray-400">{request.type}</p>
                  </div>
                </div>
                
                <p className="text-gray-300 mb-4 line-clamp-3">{request.description}</p>
                
                {request.demo_url && (
                  <a
                    href={request.demo_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 text-sm text-primary-400 hover:text-primary-300 mb-4"
                  >
                    <ExternalLink className="w-4 h-4" />
                    View Demo
                  </a>
                )}
                
                <div className="flex items-center justify-between text-sm mt-auto">
                  <div className="flex items-center gap-2 text-gray-400">
                    <MessageSquare className="w-4 h-4" />
                    <span>{request.feedback_count} feedback</span>
                  </div>
                  <div className="flex items-center gap-2 text-token-400">
                    <Coins className="w-4 h-4" />
                    <span>{request.tokens_offered} tokens</span>
                  </div>
                </div>

                <div className="flex items-center gap-2 mt-4 pt-4 border-t border-primary-500/10">
                  {request.user.avatar ? (
                    <img
                      src={request.user.avatar}
                      alt={request.user.name}
                      className="w-6 h-6 rounded-full"
                    />
                  ) : (
                    <UserInitials name={request.user.name} size="sm" />
                  )}
                  <span className="text-sm text-gray-400">
                    by {request.user.name} • {format(new Date(request.created_at), 'PP')}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}