import React, { useState, useEffect } from 'react';
import { Plus, Loader2, UserPlus, Shield, X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { useNotifications } from '../contexts/NotificationContext';
import { UserInitials } from './UserInitials';

interface TeamMember {
  id: string;
  role: 'admin' | 'member';
  member: {
    id: string;
    name: string;
    email: string;
    avatar?: string;
  };
}

export function TeamManagement() {
  const { user } = useAuth();
  const { addNotification } = useNotifications();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (user?.id) {
      loadTeamMembers();
    }
  }, [user]);

  const loadTeamMembers = async () => {
    try {
      const { data, error } = await supabase
        .from('team_members')
        .select(`
          id,
          role,
          member:profiles!member_id(
            id,
            name,
            email,
            avatar
          )
        `)
        .eq('team_owner_id', user!.id);

      if (error) throw error;
      setTeamMembers(data || []);
    } catch (err) {
      console.error('Error loading team members:', err);
      addNotification('error', 'Failed to load team members');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInviteMember = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    setIsSubmitting(true);
    try {
      // First, find the user by email
      const { data: invitedUser, error: userError } = await supabase
        .from('profiles')
        .select('id')
        .eq('email', inviteEmail.toLowerCase())
        .single();

      if (userError) {
        addNotification('error', 'User not found');
        return;
      }

      // Add team member
      const { error: inviteError } = await supabase
        .from('team_members')
        .insert([{
          team_owner_id: user.id,
          member_id: invitedUser.id,
          role: 'member'
        }]);

      if (inviteError) throw inviteError;

      addNotification('success', 'Team member added successfully');
      setInviteEmail('');
      setShowInviteForm(false);
      loadTeamMembers();
    } catch (err) {
      console.error('Error inviting member:', err);
      addNotification('error', 'Failed to add team member');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRemoveMember = async (memberId: string) => {
    if (!user) return;

    try {
      const { error } = await supabase
        .from('team_members')
        .delete()
        .eq('team_owner_id', user.id)
        .eq('member_id', memberId);

      if (error) throw error;

      addNotification('success', 'Team member removed successfully');
      loadTeamMembers();
    } catch (err) {
      console.error('Error removing member:', err);
      addNotification('error', 'Failed to remove team member');
    }
  };

  const handleToggleRole = async (memberId: string, currentRole: 'admin' | 'member') => {
    if (!user) return;

    try {
      const { error } = await supabase
        .from('team_members')
        .update({ role: currentRole === 'admin' ? 'member' : 'admin' })
        .eq('team_owner_id', user.id)
        .eq('member_id', memberId);

      if (error) throw error;

      addNotification('success', 'Member role updated successfully');
      loadTeamMembers();
    } catch (err) {
      console.error('Error updating member role:', err);
      addNotification('error', 'Failed to update member role');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-12">
        <Loader2 className="w-6 h-6 animate-spin text-primary-400" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Team Members</h2>
        {!showInviteForm && (
          <button
            onClick={() => setShowInviteForm(true)}
            className="btn-outline-gradient"
          >
            <UserPlus className="w-4 h-4" />
            Add Member
          </button>
        )}
      </div>

      {showInviteForm && (
        <div className="glass-card rounded-lg p-6">
          <form onSubmit={handleInviteMember} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Email Address
              </label>
              <input
                type="email"
                value={inviteEmail}
                onChange={e => setInviteEmail(e.target.value)}
                className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
                placeholder="Enter team member's email"
                required
              />
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={() => setShowInviteForm(false)}
                className="px-4 py-2 text-gray-300 border border-primary-500/20 rounded-lg hover:bg-primary-500/5"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn-outline-gradient"
              >
                {isSubmitting ? 'Adding...' : 'Add Member'}
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="space-y-4">
        {teamMembers.map(member => (
          <div
            key={member.id}
            className="glass-card rounded-lg p-4 flex items-center justify-between"
          >
            <div className="flex items-center gap-4">
              {member.member.avatar ? (
                <img
                  src={member.member.avatar}
                  alt={member.member.name}
                  className="w-10 h-10 rounded-full object-cover"
                />
              ) : (
                <UserInitials name={member.member.name} size="sm" />
              )}
              <div>
                <h3 className="font-medium">{member.member.name}</h3>
                <p className="text-sm text-gray-400">{member.member.email}</p>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <button
                onClick={() => handleToggleRole(member.member.id, member.role)}
                className={`p-1.5 rounded-lg transition-colors ${
                  member.role === 'admin'
                    ? 'bg-primary-500/20 text-primary-300'
                    : 'text-gray-400 hover:text-primary-300 hover:bg-primary-500/10'
                }`}
                title={member.role === 'admin' ? 'Remove admin' : 'Make admin'}
              >
                <Shield className="w-4 h-4" />
              </button>
              <button
                onClick={() => handleRemoveMember(member.member.id)}
                className="p-1.5 text-gray-400 hover:text-red-400 hover:bg-red-500/10 rounded-lg transition-colors"
                title="Remove member"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          </div>
        ))}

        {teamMembers.length === 0 && !showInviteForm && (
          <div className="text-center py-8 text-gray-400">
            No team members yet. Add your first team member!
          </div>
        )}
      </div>
    </div>
  );
}