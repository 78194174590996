import React from 'react';
import { Coins, Users, Target, MessageSquare } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export function BenefitsSection() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleJoinClick = () => {
    if (!user) {
      navigate('/auth?signup=true');
    } else {
      navigate('/dashboard');
    }
  };

  return (
    <section className="py-16 bg-dark-400">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 gradient-text">How It Works</h2>
        
        <div className="grid md:grid-cols-3 gap-8">
          <div className="glass-card p-6 rounded-lg text-center">
            <div className="w-12 h-12 bg-primary-500/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <MessageSquare className="w-6 h-6 text-primary-400" />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-gray-100">Give Feedback</h3>
            <p className="text-gray-400">
              Share your valuable insights with other entrepreneurs and earn feedback tokens
            </p>
          </div>
          
          <div className="glass-card p-6 rounded-lg text-center">
            <div className="w-12 h-12 bg-token-500/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Coins className="w-6 h-6 text-token-400" />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-gray-100">Earn Tokens</h3>
            <p className="text-gray-400">
              Each feedback session you provide earns you tokens to use on the platform
            </p>
          </div>
          
          <div className="glass-card p-6 rounded-lg text-center">
            <div className="w-12 h-12 bg-secondary-500/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Target className="w-6 h-6 text-secondary-400" />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-gray-100">Get Feedback</h3>
            <p className="text-gray-400">
              Use your earned tokens to schedule feedback sessions for your own projects
            </p>
          </div>
        </div>
        
        <div className="mt-12 text-center">
          <button 
            onClick={handleJoinClick}
            className="btn-outline-gradient inline-flex items-center gap-2 transition-transform hover:scale-105"
          >
            <Users className="w-5 h-5" />
            Join the Community
          </button>
        </div>
      </div>
    </section>
  );
}