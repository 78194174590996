import React, { useState } from 'react';
import { login, register } from '../lib/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ConfidentialityTerms } from './ConfidentialityTerms';

export function LoginForm() {
  const navigate = useNavigate();
  const { setSessionId } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showTerms, setShowTerms] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowTerms(true);
  };

  const handleAcceptTerms = async () => {
    setIsLoading(true);
    setError('');
    
    try {
      const { sessionId } = await login({ email, password });
      setSessionId(sessionId);
      navigate('/dashboard');
    } catch (err: any) {
      console.error('Login error:', err);
      setError(err.message || 'Invalid email or password');
    } finally {
      setShowTerms(false);
      setIsLoading(false);
    }
  };

  if (showTerms) {
    return (
      <ConfidentialityTerms
        onAccept={handleAcceptTerms}
        onCancel={() => setShowTerms(false)}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {error && (
        <div className="p-3 text-sm text-red-500 bg-red-500/10 rounded-lg border border-red-500/20">
          {error}
        </div>
      )}
      
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
          Email
        </label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
          required
        />
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-1">
          Password
        </label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
          required
        />
      </div>

      <button
        type="submit"
        disabled={isLoading}
        className="w-full py-2 px-4 bg-gradient-to-r from-primary-500 to-secondary-500 text-white rounded-lg hover:from-primary-600 hover:to-secondary-600 transition-colors disabled:opacity-50"
      >
        {isLoading ? 'Logging in...' : 'Log In'}
      </button>
    </form>
  );
}

export function RegisterForm() {
  const navigate = useNavigate();
  const { setSessionId } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    title: '',
    company: '',
    bio: '',
    expertise: [] as string[],
    hourlyRate: 0,
  });
  const [showTerms, setShowTerms] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowTerms(true);
  };

  const handleAcceptTerms = async () => {
    setIsLoading(true);
    setError('');
    
    try {
      const { sessionId } = await register(formData);
      setSessionId(sessionId);
      navigate('/dashboard');
    } catch (err: any) {
      console.error('Registration error:', err);
      setError(err.message || 'Registration failed. Please try again.');
    } finally {
      setShowTerms(false);
      setIsLoading(false);
    }
  };

  if (showTerms) {
    return (
      <ConfidentialityTerms
        onAccept={handleAcceptTerms}
        onCancel={() => setShowTerms(false)}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {error && (
        <div className="p-3 text-sm text-red-500 bg-red-500/10 rounded-lg border border-red-500/20">
          {error}
        </div>
      )}

      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">
          Full Name
        </label>
        <input
          id="name"
          type="text"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
          required
        />
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
          Email
        </label>
        <input
          id="email"
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
          required
        />
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-1">
          Password
        </label>
        <input
          id="password"
          type="password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
          required
          minLength={8}
        />
        <p className="text-xs text-gray-400 mt-1">Must be at least 8 characters long</p>
      </div>

      <button
        type="submit"
        disabled={isLoading}
        className="w-full py-2 px-4 bg-gradient-to-r from-primary-500 to-secondary-500 text-white rounded-lg hover:from-primary-600 hover:to-secondary-600 transition-colors disabled:opacity-50"
      >
        {isLoading ? 'Creating account...' : 'Create Account'}
      </button>
    </form>
  );
}