import React from 'react';
import { Monitor, Mic, MicOff, Video, VideoOff, PhoneOff } from 'lucide-react';
import { useDaily } from '@daily-co/daily-react';

export function VideoControls() {
  const callObject = useDaily();
  const [isMuted, setIsMuted] = React.useState(false);
  const [isVideoOff, setIsVideoOff] = React.useState(false);
  const [isScreenSharing, setIsScreenSharing] = React.useState(false);

  const toggleAudio = () => {
    if (callObject) {
      const enabled = !isMuted;
      callObject.setLocalAudio(enabled);
      setIsMuted(!enabled);
    }
  };

  const toggleVideo = () => {
    if (callObject) {
      const enabled = !isVideoOff;
      callObject.setLocalVideo(enabled);
      setIsVideoOff(!enabled);
    }
  };

  const toggleScreenShare = async () => {
    if (callObject) {
      try {
        if (isScreenSharing) {
          await callObject.stopScreenShare();
        } else {
          await callObject.startScreenShare();
        }
        setIsScreenSharing(!isScreenSharing);
      } catch (e) {
        console.error('Error toggling screen share:', e);
      }
    }
  };

  const leaveCall = () => {
    if (callObject) {
      callObject.leave();
    }
  };

  return (
    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center gap-4 p-4 bg-dark-300/90 rounded-lg backdrop-blur-sm">
      <button
        onClick={toggleAudio}
        className={`p-3 rounded-full ${
          isMuted ? 'bg-red-500/20 text-red-400' : 'bg-primary-500/20 text-primary-400'
        } hover:bg-primary-500/30 transition-colors`}
      >
        {isMuted ? <MicOff className="w-5 h-5" /> : <Mic className="w-5 h-5" />}
      </button>

      <button
        onClick={toggleVideo}
        className={`p-3 rounded-full ${
          isVideoOff ? 'bg-red-500/20 text-red-400' : 'bg-primary-500/20 text-primary-400'
        } hover:bg-primary-500/30 transition-colors`}
      >
        {isVideoOff ? <VideoOff className="w-5 h-5" /> : <Video className="w-5 h-5" />}
      </button>

      <button
        onClick={toggleScreenShare}
        className={`p-3 rounded-full ${
          isScreenSharing ? 'bg-primary-500/40 text-primary-300' : 'bg-primary-500/20 text-primary-400'
        } hover:bg-primary-500/30 transition-colors`}
      >
        <Monitor className="w-5 h-5" />
      </button>

      <button
        onClick={leaveCall}
        className="p-3 rounded-full bg-red-500/20 text-red-400 hover:bg-red-500/30 transition-colors"
      >
        <PhoneOff className="w-5 h-5" />
      </button>
    </div>
  );
}