import React, { useState } from 'react';
import { Calendar, Clock, Coins, Linkedin } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { UserInitials } from './UserInitials';
import { useNavigate } from 'react-router-dom';
import type { User } from '../lib/models/user';

interface UserCardProps {
  user: User;
}

export function UserCard({ user }: UserCardProps) {
  const { user: currentUser } = useAuth();
  const navigate = useNavigate();

  const handleBookCall = () => {
    if (!currentUser) {
      navigate('/auth');
      return;
    }
    navigate(`/users/${user.id}`);
  };

  return (
    <>
      <div className="glass-card rounded-lg p-6 flex flex-col h-full hover:border-primary-500/20 transition-all duration-300">
        <div className="flex items-start gap-6">
          {user.avatar ? (
            <img
              src={user.avatar}
              alt={user.name}
              className="w-16 h-16 rounded-full object-cover ring-2 ring-primary-500/20"
            />
          ) : (
            <UserInitials name={user.name} size="lg" className="ring-2 ring-primary-500/20" />
          )}
          <div className="flex-1">
            <h3 className="text-xl font-semibold text-gray-100 mb-1">{user.name}</h3>
            <p className="text-gray-400 text-sm">{user.title} at {user.company}</p>
            
            <div className="mt-4 flex items-center gap-6 text-sm text-gray-400">
              <div className="icon-align">
                <Clock className="w-4 h-4 text-primary-400" />
                <span>20 min</span>
              </div>
              <div className="icon-align">
                <Coins className="w-4 h-4 text-token-400" />
                <span className="font-medium">10 tokens</span>
              </div>
            </div>
            
            <div className="mt-4">
              <p className="text-gray-300 text-sm line-clamp-2">{user.bio}</p>
            </div>
            
            <div className="mt-4 flex flex-wrap gap-2 mb-6">
              {user.expertise?.map((tag) => (
                <span
                  key={tag.value || tag}
                  className="px-3 py-1 bg-primary-500/10 text-primary-300 rounded-full text-xs border border-primary-500/20"
                >
                  {tag.label || tag}
                </span>
              ))}
            </div>
            
            {user.linkedin_url && (
              <a
                href={user.linkedin_url}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 text-sm text-primary-400 hover:text-primary-300 transition-colors mb-4"
              >
                <Linkedin className="w-4 h-4" />
                View LinkedIn Profile
              </a>
            )}
          </div>
        </div>

        <button
          onClick={handleBookCall}
          className="mt-auto w-full flex items-center justify-center gap-2 btn-outline-gradient"
        >
          <Calendar className="w-4 h-4" />
          Schedule Feedback Call
        </button>
      </div>
    </>
  );
}