import { supabase } from '../supabase';
import type { Database } from '../../types/database';

export type User = Database['public']['Tables']['profiles']['Row'];

export const UserModel = {
  async getCurrentUser() {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) return null;

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();

      if (error) {
        if (error.code === 'PGRST116') {
          // Profile doesn't exist yet, create it
          return this.createProfile(session.user.id, {
            email: session.user.email!,
            name: session.user.user_metadata.name || session.user.email!.split('@')[0],
            tokens: 2,
            role: 'user'
          });
        }
        throw error;
      }
      return data;
    } catch (error) {
      console.error('Error getting current user:', error);
      throw error;
    }
  },

  async createProfile(userId: string, data: Partial<User>) {
    try {
      const { data: profile, error } = await supabase
        .from('profiles')
        .insert([{
          id: userId,
          email: data.email,
          name: data.name,
          tokens: 2,
          role: 'user',
          expertise: [],
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (error) throw error;
      return profile;
    } catch (error) {
      console.error('Error creating profile:', error);
      throw error;
    }
  },

  async updateProfile(userId: string, data: Partial<User> & { avatar?: File }) {
    try {
      const updates: Partial<User> = {
        ...data,
        updated_at: new Date().toISOString()
      };

      // Handle avatar upload if provided
      if (data.avatar instanceof File) {
        const fileExt = data.avatar.name.split('.').pop()?.toLowerCase();
        if (!fileExt) throw new Error('Invalid file extension');

        const filePath = `${userId}/avatar.${fileExt}`;

        // Upload the file
        const { error: uploadError } = await supabase.storage
          .from('avatars')
          .upload(filePath, data.avatar, { 
            upsert: true,
            contentType: data.avatar.type 
          });

        if (uploadError) throw uploadError;

        // Get the public URL
        const { data: urlData } = supabase.storage
          .from('avatars')
          .getPublicUrl(filePath);

        if (!urlData.publicUrl) {
          throw new Error('Failed to get public URL for uploaded image');
        }

        updates.avatar = urlData.publicUrl;
      }

      // Update profile
      const { data: profile, error } = await supabase
        .from('profiles')
        .update(updates)
        .eq('id', userId)
        .select()
        .single();

      if (error) throw error;
      return profile;
    } catch (error) {
      console.error('Error updating profile:', error);
      throw error instanceof Error 
        ? error 
        : new Error('Failed to update profile');
    }
  },

  async getAllUsers() {
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  },

  async getProfile(userId: string) {
    const { data: profile, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', userId)
      .single();

    if (error) throw error;
    return profile;
  }
};