import React from 'react';
import { Header } from '../components/Header';
import { FeatureSuggestions } from '../components/FeatureSuggestions';
import { Lightbulb, Users, Target, MessageSquare } from 'lucide-react';

export function FeatureSuggestionsPage() {
  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-8">
            <h1 className="text-4xl font-bold mb-3">Help Shape InsightSwap</h1>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto">
              Share your ideas and vote on features that matter most to you
            </p>
          </div>

          <div className="grid md:grid-cols-4 gap-4 mb-8">
            <div className="glass-card rounded-lg p-4">
              <div className="w-10 h-10 bg-primary-500/10 rounded-full flex items-center justify-center mb-3">
                <Lightbulb className="w-5 h-5 text-primary-400" />
              </div>
              <h3 className="text-base font-bold mb-2">Suggest Features</h3>
              <p className="text-sm text-gray-400">
                Share your ideas for improvements
              </p>
            </div>

            <div className="glass-card rounded-lg p-4">
              <div className="w-10 h-10 bg-secondary-500/10 rounded-full flex items-center justify-center mb-3">
                <Target className="w-5 h-5 text-secondary-400" />
              </div>
              <h3 className="text-base font-bold mb-2">Vote on Ideas</h3>
              <p className="text-sm text-gray-400">
                Help prioritize what matters most
              </p>
            </div>

            <div className="glass-card rounded-lg p-4">
              <div className="w-10 h-10 bg-token-500/10 rounded-full flex items-center justify-center mb-3">
                <MessageSquare className="w-5 h-5 text-token-400" />
              </div>
              <h3 className="text-base font-bold mb-2">Discuss & Refine</h3>
              <p className="text-sm text-gray-400">
                Engage in productive discussions
              </p>
            </div>

            <div className="glass-card rounded-lg p-4">
              <div className="w-10 h-10 bg-primary-500/10 rounded-full flex items-center justify-center mb-3">
                <Users className="w-5 h-5 text-primary-400" />
              </div>
              <h3 className="text-base font-bold mb-2">Track Progress</h3>
              <p className="text-sm text-gray-400">
                Follow feature development
              </p>
            </div>
          </div>

          <FeatureSuggestions />
        </div>
      </main>
    </div>
  );
}