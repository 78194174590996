import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Coins, Check, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export function TokenPricingPage() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleGetStarted = () => {
    if (!user) {
      navigate('/auth?signup=true');
    } else {
      navigate('/dashboard');
    }
  };

  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Token Pricing</h1>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto">
              Our token system ensures high-quality feedback by incentivizing meaningful interactions
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto mb-16">
            <div className="glass-card rounded-lg p-8">
              <div className="w-12 h-12 bg-primary-500/10 rounded-full flex items-center justify-center mb-6">
                <Coins className="w-6 h-6 text-primary-400" />
              </div>
              <h2 className="text-2xl font-bold mb-2">Earning Tokens</h2>
              <ul className="space-y-4">
                <li className="flex items-start gap-2">
                  <Check className="w-5 h-5 text-primary-400 mt-1 flex-shrink-0" />
                  <span className="text-gray-300">Get 2 tokens when you sign up</span>
                </li>
                <li className="flex items-start gap-2">
                  <Check className="w-5 h-5 text-primary-400 mt-1 flex-shrink-0" />
                  <span className="text-gray-300">Earn 2 tokens for each feedback session you provide</span>
                </li>
                <li className="flex items-start gap-2">
                  <Check className="w-5 h-5 text-primary-400 mt-1 flex-shrink-0" />
                  <span className="text-gray-300">Bonus tokens for high-quality feedback</span>
                </li>
              </ul>
            </div>

            <div className="glass-card rounded-lg p-8">
              <div className="w-12 h-12 bg-secondary-500/10 rounded-full flex items-center justify-center mb-6">
                <ArrowRight className="w-6 h-6 text-secondary-400" />
              </div>
              <h2 className="text-2xl font-bold mb-2">Using Tokens</h2>
              <ul className="space-y-4">
                <li className="flex items-start gap-2">
                  <Check className="w-5 h-5 text-secondary-400 mt-1 flex-shrink-0" />
                  <span className="text-gray-300">1 token per feedback session you receive</span>
                </li>
                <li className="flex items-start gap-2">
                  <Check className="w-5 h-5 text-secondary-400 mt-1 flex-shrink-0" />
                  <span className="text-gray-300">Purchase additional tokens for $25 each</span>
                </li>
                <li className="flex items-start gap-2">
                  <Check className="w-5 h-5 text-secondary-400 mt-1 flex-shrink-0" />
                  <span className="text-gray-300">Tokens never expire</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="glass-card rounded-lg p-8 max-w-3xl mx-auto text-center">
            <h2 className="text-2xl font-bold mb-6">Token Packages</h2>
            <div className="grid md:grid-cols-3 gap-6">
              <div className="p-6 rounded-lg bg-dark-200/50 border border-primary-500/20">
                <h3 className="text-xl font-bold mb-2">Starter</h3>
                <p className="text-3xl font-bold mb-4">$50</p>
                <p className="text-gray-400 mb-4">2 Tokens</p>
                <button 
                  onClick={handleGetStarted}
                  className="w-full py-2 px-4 border border-primary-400 text-primary-400 rounded-lg hover:bg-primary-400/10 transition-all"
                >
                  Get Started
                </button>
              </div>

              <div className="p-6 rounded-lg bg-dark-200/50 border border-primary-500/20 relative transform scale-105">
                <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-gradient-to-r from-primary-500 to-secondary-500 text-white px-4 py-1 rounded-full text-sm">
                  Popular
                </div>
                <h3 className="text-xl font-bold mb-2">Pro</h3>
                <p className="text-3xl font-bold mb-4">$100</p>
                <p className="text-gray-400 mb-4">5 Tokens</p>
                <button 
                  onClick={handleGetStarted}
                  className="w-full py-2 px-4 bg-gradient-to-r from-primary-500 to-secondary-500 text-white rounded-lg hover:from-primary-600 hover:to-secondary-600 transition-all"
                >
                  Get Started
                </button>
              </div>

              <div className="p-6 rounded-lg bg-dark-200/50 border border-primary-500/20">
                <h3 className="text-xl font-bold mb-2">Enterprise</h3>
                <p className="text-3xl font-bold mb-4">$200</p>
                <p className="text-gray-400 mb-4">12 Tokens</p>
                <button 
                  onClick={handleGetStarted}
                  className="w-full py-2 px-4 border border-primary-400 text-primary-400 rounded-lg hover:bg-primary-400/10 transition-all"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}