import React from 'react';
import { Camera, Loader2 } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { UserModel } from '../../lib/models/user';
import { expertise } from '../../data/expertise';
import { UserInitials } from '../UserInitials';
import { supabase } from '../../lib/supabase';

export function ProfileSection() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const [profile, setProfile] = React.useState({
    name: '',
    title: '',
    company: '',
    bio: '',
    expertise: [] as string[],
    hourly_rate: 0,
    avatar: ''
  });

  React.useEffect(() => {
    if (user?.id) {
      loadProfile(user.id);
    }
  }, [user]);

  const loadProfile = async (userId: string) => {
    try {
      setIsLoading(true);
      setError(null);
      const userData = await UserModel.getProfile(userId);
      
      if (!userData) {
        setError('Profile not found');
        return;
      }

      setProfile({
        name: userData.name || '',
        title: userData.title || '',
        company: userData.company || '',
        bio: userData.bio || '',
        expertise: userData.expertise || [],
        hourly_rate: userData.hourly_rate || 0,
        avatar: userData.avatar || ''
      });
    } catch (err) {
      console.error('Error loading profile:', err);
      setError('Failed to load profile');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.id) return;

    // Validate required fields
    if (!profile.name.trim()) {
      setError('Name is required');
      return;
    }
    
    const formData = {
      name: profile.name.trim(),
      title: profile.title.trim(),
      company: profile.company.trim(),
      bio: profile.bio.trim(),
      expertise: profile.expertise || [],
      hourly_rate: Number(profile.hourly_rate) || 0
    };

    try {
      setIsLoading(true);
      setError(null);
      setSuccess(null);

      const updatedProfile = await UserModel.updateProfile(user.id, formData);
      
      if (!updatedProfile) {
        throw new Error('Failed to update profile');
      }
      
      setSuccess('Profile updated successfully!');
      
      // Reload profile to get latest data
      await loadProfile(user.id);
    } catch (err) {
      console.error('Error updating profile:', err);
      setError(err instanceof Error ? err.message : 'Failed to update profile');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0] || !user?.id) return;

    setIsLoading(true);
    setError(null);
    setSuccess(null);

    const file = e.target.files[0];
    
    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError('Image size must be less than 5MB');
      setIsLoading(false);
      return;
    }

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file');
      setIsLoading(false);
      return;
    }

    try {
      // Generate a unique filename with original extension
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if (!fileExt) {
        throw new Error('Invalid file extension');
      }

      const filePath = `${user.id}/avatar.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(filePath, file, { upsert: true });

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: urlData } = supabase.storage
        .from('avatars')
        .getPublicUrl(filePath);

      if (!urlData.publicUrl) {
        throw new Error('Failed to get public URL for uploaded image');
      }

      await UserModel.updateProfile(user.id, {
        avatar: urlData.publicUrl
      });

      setProfile(prev => ({ ...prev, avatar: urlData.publicUrl }));
      setSuccess('Profile picture updated successfully!');
      
      // Reload profile to get latest data
      await loadProfile(user.id);
    } catch (err) {
      console.error('Error uploading image:', err);
      setError(err instanceof Error ? err.message : 'Failed to upload image');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="glass-card rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-6">Profile Information</h2>

      {error && (
        <div className="mb-4 p-3 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
          {error}
        </div>
      )}

      {success && (
        <div className="mb-4 p-3 bg-green-500/10 border border-green-500/20 rounded-lg text-green-400">
          {success}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex items-center gap-6">
          <div className="relative">
            {profile.avatar ? (
              <img
                src={profile.avatar}
                alt={profile.name}
                className="w-24 h-24 rounded-full object-cover"
              />
            ) : (
              <UserInitials name={profile.name} size="lg" />
            )}
            <label className="absolute bottom-0 right-0 p-1.5 bg-primary-500 rounded-full cursor-pointer hover:bg-primary-600 transition-colors">
              <Camera className="w-4 h-4 text-white" />
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageUpload}
              />
            </label>
          </div>
          <div>
            <h3 className="font-medium text-lg">{profile.name || 'Your Name'}</h3>
            <p className="text-gray-400">{profile.title || 'Your Title'}</p>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Full Name
            </label>
            <input
              type="text"
              value={profile.name}
              onChange={e => setProfile(prev => ({ ...prev, name: e.target.value }))}
              className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Job Title
            </label>
            <input
              type="text"
              value={profile.title}
              onChange={e => setProfile(prev => ({ ...prev, title: e.target.value }))}
              className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Company
            </label>
            <input
              type="text"
              value={profile.company}
              onChange={e => setProfile(prev => ({ ...prev, company: e.target.value }))}
              className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              LinkedIn Profile
            </label>
            <input
              type="url"
              value={profile.linkedin_url || ''}
              onChange={e => setProfile(prev => ({ ...prev, linkedin_url: e.target.value }))}
              placeholder="https://linkedin.com/in/username"
              className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Hourly Rate ($)
            </label>
            <input
              type="number"
              value={profile.hourly_rate}
              onChange={e => setProfile(prev => ({ ...prev, hourly_rate: Number(e.target.value) }))}
              className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Bio
          </label>
          <textarea
            value={profile.bio}
            onChange={e => setProfile(prev => ({ ...prev, bio: e.target.value }))}
            rows={4}
            className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
            placeholder="Tell others about your expertise and how you can help them..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Areas of Expertise
          </label>
          <div className="flex flex-wrap gap-2">
            {expertise.map(tag => (
              <button
                key={tag.value}
                type="button"
                onClick={() => {
                  setProfile(prev => ({
                    ...prev,
                    expertise: prev.expertise.includes(tag.label)
                      ? prev.expertise.filter(e => e !== tag.label)
                      : [...prev.expertise, tag.label]
                  }));
                }}
                className={`px-3 py-1 rounded-full text-sm border transition-colors ${
                  profile.expertise.includes(tag.label)
                    ? 'bg-primary-500/20 border-primary-500/40 text-primary-300'
                    : 'border-primary-500/20 text-gray-400 hover:border-primary-500/40 hover:text-primary-300'
                }`}
              >
                {tag.label}
              </button>
            ))}
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isLoading}
            className="btn-outline-gradient disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin" />
                <span>Saving...</span>
              </>
            ) : (
              'Save Changes'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}