import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { LogIn, UserPlus } from 'lucide-react';

export function Header() {
  const { user, signOut } = useAuth();

  return (
    <header className="fixed top-0 left-0 right-0 z-50 backdrop-blur-md bg-dark-300/80 border-b border-primary-500/20 h-16">
      <div className="max-w-7xl mx-auto px-4 h-full flex items-center relative">
        <div className="flex items-center justify-between w-full">
          <Link to="/" className="flex items-center gap-2">
            <img src="/logo.svg" alt="InsightSwap" className="h-8 w-auto" />
            <h1 className="text-2xl text-white">
              <span className="font-normal">Insight</span>
              <span className="font-bold">Swap</span>
            </h1>
          </Link>
          <nav className="flex items-center gap-4">
            {user ? (
              <div className="flex items-center gap-3">
                <Link
                  to="/schedule"
                  className="text-gray-300 hover:text-gray-100 transition-colors"
                >
                  Schedule Call
                </Link>
                <Link
                  to="/dashboard"
                  className="text-gray-300 hover:text-gray-100 transition-colors"
                >
                  Dashboard
                </Link>
                <div className="h-4 w-px bg-primary-500/20" />
                <button
                  onClick={() => signOut()}
                  className="text-gray-300 hover:text-gray-100 transition-colors"
                >
                  Sign Out
                </button>
              </div>
            ) : (
              <div className="flex items-center gap-6">
                <div className="flex items-center gap-3">
                  <Link
                    to="/auth"
                    className="flex items-center gap-2 px-6 py-2 text-gray-300 hover:text-gray-100 transition-all hover:scale-105"
                  >
                    <LogIn className="w-4 h-4" />
                    Sign In
                  </Link>
                  <Link
                    to="/auth?signup=true"
                    className="btn-outline-gradient px-4 transition-transform hover:scale-105"
                  >
                    Sign Up
                  </Link>
                </div>
              </div>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
}