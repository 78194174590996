import { supabase } from '../supabase';

const DAILY_API_KEY = import.meta.env.VITE_DAILY_API_KEY;
const DAILY_API_URL = 'https://api.daily.co/v1';
const DAILY_DOMAIN = 'insightswap.daily.co';

if (!DAILY_API_KEY) {
  throw new Error('Missing Daily.co API key');
}

export const dailyService = {
  async createRoom(bookingId: string) {
    try {
      // First check if room already exists
      const { data: booking } = await supabase
        .from('bookings')
        .select('daily_room_url, daily_room_name')
        .eq('id', bookingId)
        .single();

      if (booking?.daily_room_url) {
        return booking.daily_room_url;
      }

      // Create new room
      const roomName = `booking-${bookingId}`;
      const response = await fetch(`${DAILY_API_URL}/rooms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${DAILY_API_KEY}`,
        },
        body: JSON.stringify({
          name: roomName,
          privacy: 'private',
          properties: {
            enable_screenshare: true,
            enable_chat: true,
            start_video_off: false,
            start_audio_off: false,
            exp: Math.round(Date.now() / 1000) + 3600 * 24, // 24 hours
          }
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create Daily.co room');
      }

      const roomData = await response.json();
      const roomUrl = roomData.url;

      // Update booking with room info
      const { error: updateError } = await supabase
        .from('bookings')
        .update({
          daily_room_url: roomUrl,
          daily_room_name: roomName
        })
        .eq('id', bookingId);

      if (updateError) throw updateError;

      return roomUrl;
    } catch (error) {
      console.error('Error creating video room:', error);
      throw error;
    }
  }
};