import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Calendar, Code, Layout, Zap } from 'lucide-react';

const releases = [
  {
    version: 'v1.2.0',
    date: 'March 20, 2024',
    type: 'Feature',
    icon: Zap,
    changes: [
      'Added token packages for easier purchasing',
      'Improved matching algorithm for better feedback sessions',
      'New calendar integration for scheduling',
    ]
  },
  {
    version: 'v1.1.0',
    date: 'March 10, 2024',
    type: 'Enhancement',
    icon: Layout,
    changes: [
      'Redesigned dashboard interface',
      'Added feedback analytics',
      'Improved notification system',
    ]
  },
  {
    version: 'v1.0.1',
    date: 'March 1, 2024',
    type: 'Bug Fix',
    icon: Code,
    changes: [
      'Fixed scheduling conflicts in certain timezones',
      'Improved error handling for token transactions',
      'Fixed notification delivery issues',
    ]
  }
];

export function ChangelogPage() {
  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-24 pb-16">
        <div className="max-w-3xl mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Changelog</h1>
            <p className="text-xl text-gray-400">
              Latest updates and improvements to InsightSwap
            </p>
          </div>

          <div className="space-y-8">
            {releases.map((release, index) => (
              <div key={index} className="glass-card rounded-lg p-6">
                <div className="flex items-start gap-4">
                  <div className="w-10 h-10 rounded-lg bg-primary-500/10 flex items-center justify-center flex-shrink-0">
                    <release.icon className="w-5 h-5 text-primary-400" />
                  </div>
                  <div className="flex-1">
                    <div className="flex items-center gap-3 mb-2">
                      <h2 className="text-xl font-bold">{release.version}</h2>
                      <div className="flex items-center gap-2 text-sm text-gray-400">
                        <Calendar className="w-4 h-4" />
                        <span>{release.date}</span>
                      </div>
                    </div>
                    <ul className="space-y-2">
                      {release.changes.map((change, changeIndex) => (
                        <li key={changeIndex} className="text-gray-300">
                          • {change}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}