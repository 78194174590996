import React from 'react';
import { Check, MessageSquare, Building2, ExternalLink, Coins } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export function PricingSection() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handlePlanClick = (plan: 'starter' | 'pro' | 'enterprise') => {
    if (!user) {
      navigate('/auth?signup=true');
      return;
    }

    if (plan === 'enterprise') {
      window.location.href = 'mailto:sales@insightswap.com';
      return;
    }

    navigate('/dashboard');
  };

  return (
    <section className="py-16 bg-dark-400 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-radial from-primary-500/5 via-transparent to-transparent" />
      
      <div className="max-w-7xl mx-auto px-4 relative">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4 gradient-text">Simple, Token-Based Pricing</h2>
          <p className="text-xl text-gray-400">Choose the plan that works best for your needs</p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {/* Free Tier */}
          <div className="glass-card rounded-lg p-8 hover:border-primary-400/40 transition-all duration-300">
            <h3 className="text-2xl font-bold mb-4 text-gray-100">Starter</h3>
            <div className="mb-4">
              <span className="text-4xl font-bold text-gray-100">Free</span>
            </div>
            <p className="text-gray-400 mb-6">Perfect for trying out the platform</p>
            
            <ul className="space-y-4 mb-8">
              <li className="flex items-center gap-2 text-gray-300">
                <Check className="w-5 h-5 text-primary-400" />
                <span>2 feedback tokens to start</span>
              </li>
              <li className="flex items-center gap-2 text-gray-300">
                <Check className="w-5 h-5 text-primary-400" />
                <span>Basic matching algorithm</span>
              </li>
              <li className="flex items-center gap-2 text-gray-300">
                <Check className="w-5 h-5 text-primary-400" />
                <span>Community access</span>
              </li>
            </ul>

            <button 
              onClick={() => handlePlanClick('starter')}
              className="w-full py-2 px-4 border border-primary-400 text-primary-400 rounded-lg hover:bg-primary-400/10 transition-all hover:scale-105"
            >
              Get Started
            </button>
          </div>

          {/* Pro Tier */}
          <div className="glass-card rounded-lg p-8 relative border-primary-500/50 hover:border-primary-400/70 transition-all duration-300 transform scale-105">
            <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-gradient-to-r from-primary-500 to-secondary-500 text-white px-4 py-1 rounded-full text-sm z-20 shadow-lg">
              Most Popular
            </div>
            <h3 className="text-2xl font-bold mb-4 text-gray-100">Pro</h3>
            <div className="mb-4">
              <span className="text-4xl font-bold text-gray-100">$49</span>
              <span className="text-gray-400">/month</span>
            </div>
            <p className="text-gray-400 mb-6">For serious product builders</p>
            
            <ul className="space-y-4 mb-8">
              <li className="flex items-center gap-2 text-gray-300">
                <Check className="w-5 h-5 text-secondary-400" />
                <span>10 feedback tokens monthly</span>
              </li>
              <li className="flex items-center gap-2 text-gray-300">
                <Check className="w-5 h-5 text-secondary-400" />
                <span>Priority matching</span>
              </li>
              <li className="flex items-center gap-2 text-gray-300">
                <Check className="w-5 h-5 text-secondary-400" />
                <span>Advanced filters</span>
              </li>
              <li className="flex items-center gap-2 text-gray-300">
                <Check className="w-5 h-5 text-secondary-400" />
                <span>Calendar integration</span>
              </li>
            </ul>

            <button 
              onClick={() => handlePlanClick('pro')}
              className="w-full py-2 px-4 bg-gradient-to-r from-primary-500 to-secondary-500 text-white rounded-lg hover:from-primary-600 hover:to-secondary-600 transition-all hover:scale-105"
            >
              Subscribe Now
            </button>
          </div>

          {/* Enterprise Tier */}
          <div className="glass-card rounded-lg p-8 hover:border-primary-400/40 transition-all duration-300">
            <h3 className="text-2xl font-bold mb-4 text-gray-100">Enterprise</h3>
            <div className="mb-4">
              <span className="text-4xl font-bold text-gray-100">Custom</span>
            </div>
            <p className="text-gray-400 mb-6">For organizations and teams</p>
            
            <ul className="space-y-4 mb-8">
              <li className="flex items-center gap-2 text-gray-300">
                <Check className="w-5 h-5 text-primary-400" />
                <span>Unlimited tokens</span>
              </li>
              <li className="flex items-center gap-2 text-gray-300">
                <Check className="w-5 h-5 text-primary-400" />
                <span>Team management</span>
              </li>
              <li className="flex items-center gap-2 text-gray-300">
                <Check className="w-5 h-5 text-primary-400" />
                <span>Custom integrations</span>
              </li>
              <li className="flex items-center gap-2 text-gray-300">
                <Check className="w-5 h-5 text-primary-400" />
                <span>Dedicated support</span>
              </li>
            </ul>

            <button 
              onClick={() => handlePlanClick('enterprise')}
              className="w-full py-2 px-4 border border-primary-400 text-primary-400 rounded-lg hover:bg-primary-400/10 transition-all hover:scale-105 inline-flex items-center justify-center gap-2"
            >
              <Building2 className="w-5 h-5" />
              Contact Sales
            </button>
          </div>
        </div>

        <div className="mt-12 text-center">
          <p className="text-gray-400 mb-4">
            Need more tokens? Purchase additional tokens anytime for $25 each
          </p>
          <button
            onClick={() => navigate('/pricing/tokens')}
            className="text-primary-400 hover:text-primary-300 inline-flex items-center gap-2 group transition-colors"
          >
            <Coins className="w-5 h-5" />
            Learn more about token pricing
            <ExternalLink className="w-4 h-4 group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
          </button>
        </div>
      </div>
    </section>
  );
}