import React, { useState, useCallback } from 'react';
import { SearchFilters } from '../components/SearchFilters';
import { UserCard } from '../components/UserCard';
import { useUsers } from '../hooks/useUsers';
import { useAuth } from '../contexts/AuthContext';
import { DashboardLayout } from '../components/layouts/DashboardLayout';
import { Navigate } from 'react-router-dom';
import { LoadingState } from '../components/ui/LoadingState';

export function ScheduleCallPage() {
  const { user, isLoading: authLoading } = useAuth();
  const { users, isLoading, error, fetchUsers } = useUsers();

  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState<{
    expertise: string[];
    priceRange: [number, number];
  }>({
    expertise: [],
    priceRange: [0, 200]
  });

  React.useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSearch = useCallback((query: string) => {
    setSearchQuery(query);
    fetchUsers({
      expertise: filters.expertise,
      priceRange: filters.priceRange,
      search: query
    });
  }, [filters, fetchUsers]);

  const handleFilterChange = useCallback((newFilters: any) => {
    setFilters(prev => ({
      ...prev,
      expertise: newFilters.expertise || prev.expertise,
      priceRange: newFilters.priceRange || prev.priceRange
    }));
    fetchUsers({
      expertise: newFilters.expertise || filters.expertise,
      priceRange: newFilters.priceRange || filters.priceRange,
      search: searchQuery
    });
  }, [filters, searchQuery, fetchUsers]);

  if (authLoading) {
    return <LoadingState message="Loading..." />;
  }

  if (!user) {
    return <Navigate to="/auth" replace />;
  }

  return (
    <DashboardLayout>
      <div className="min-h-screen bg-dark-300">
        <main className="p-8">
          <div className="max-w-7xl mx-auto">
            <div className="mb-8">
              <h1 className="text-3xl font-bold mb-2">Schedule a Call</h1>
              <p className="text-gray-400">
                Find and connect with customers who can provide valuable feedback for your product.
              </p>
            </div>

            <SearchFilters onSearch={handleSearch} onFilterChange={handleFilterChange} />
            
            <div className="mt-8 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {isLoading ? (
                <div className="col-span-full">
                  <LoadingState message="Loading users..." />
                </div>
              ) : error ? (
                <div className="col-span-full text-center py-12 text-red-400">{error}</div>
              ) : (
                <>
                  {users.map((user) => (
                    <UserCard key={user.id} user={user} />
                  ))}
                  {users.length === 0 && (
                    <div className="col-span-full text-center py-12">
                      <p className="text-gray-400 text-lg">
                        No users found matching your criteria. Try adjusting your filters.
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </DashboardLayout>
  );
}