import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Calendar, Clock, DollarSign, ArrowLeft } from 'lucide-react';
import { format, parseISO, isAfter } from 'date-fns';
import { useAuth } from '../contexts/AuthContext';
import { UserInitials } from '../components/UserInitials';
import { supabase } from '../lib/supabase';
import { useNotifications } from '../contexts/NotificationContext';
import { DashboardLayout } from '../components/layouts/DashboardLayout';
import { LoadingState } from '../components/ui/LoadingState';
import type { User } from '../lib/models/user';
import type { TimeSlot } from '../lib/models/timeSlots';

export function UserProfilePage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const { addNotification } = useNotifications();
  const [user, setUser] = React.useState<User | null>(null);
  const [timeSlots, setTimeSlots] = React.useState<TimeSlot[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [selectedSlot, setSelectedSlot] = React.useState<TimeSlot | null>(null);
  const [projectDescription, setProjectDescription] = React.useState('');
  const [isBooking, setIsBooking] = React.useState(false);

  React.useEffect(() => {
    if (userId) {
      loadUserProfile();
      loadTimeSlots();
    }
  }, [userId]);

  const loadUserProfile = async () => {
    try {
      const { data: profile, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) throw error;
      setUser(profile);
    } catch (err) {
      console.error('Error loading profile:', err);
      setError('Failed to load user profile');
    }
  };

  const loadTimeSlots = async () => {
    try {
      const { data: slots, error } = await supabase
        .from('time_slots')
        .select()
        .eq('provider_id', userId)
        .eq('is_booked', false)
        .gte('start_time', new Date().toISOString())
        .order('start_time', { ascending: true });

      if (error) throw error;
      setTimeSlots(slots);
    } catch (err) {
      console.error('Error loading time slots:', err);
      setError('Failed to load available time slots');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBookSlot = async () => {
    if (!currentUser) {
      navigate('/auth');
      return;
    }

    if (!selectedSlot || !projectDescription.trim()) {
      setError('Please select a time slot and provide a project description');
      return;
    }

    setIsBooking(true);
    setError(null);

    try {
      // Create the booking
      const { error: bookingError } = await supabase
        .from('bookings')
        .insert([{
          time_slot_id: selectedSlot.id,
          provider_id: userId,
          customer_id: currentUser.id,
          project_description: projectDescription.trim(),
          status: 'pending'
        }]);

      if (bookingError) throw bookingError;

      // Update time slot status
      const { error: updateError } = await supabase
        .from('time_slots')
        .update({ is_booked: true })
        .eq('id', selectedSlot.id);

      if (updateError) throw updateError;

      // Create notifications for both users
      await supabase.from('notifications').insert([
        {
          user_id: currentUser.id,
          title: 'Booking Confirmation',
          content: `Your booking request with ${user?.name} has been submitted`,
          type: 'success'
        },
        {
          user_id: userId,
          title: 'New Booking Request',
          content: `${currentUser.name} has requested a feedback session`,
          type: 'info'
        }
      ]);

      addNotification('success', 'Booking request submitted successfully');
      navigate('/dashboard');
    } catch (err) {
      console.error('Booking error:', err);
      setError('Failed to book the time slot');
    } finally {
      setIsBooking(false);
    }
  };

  if (!user || isLoading) {
    return (
      <DashboardLayout>
        <LoadingState message="Loading profile..." />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="min-h-screen bg-dark-300">
        <div className="max-w-7xl mx-auto px-4 py-8">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center gap-2 text-gray-400 hover:text-gray-300 transition-colors mb-8"
          >
            <ArrowLeft className="w-4 h-4" />
            Back
          </button>

          <div className="grid lg:grid-cols-3 gap-8">
            {/* Profile Info */}
            <div className="lg:col-span-1">
              <div className="glass-card rounded-lg p-6 sticky top-24">
                <div className="flex items-start gap-4 mb-6">
                  {user.avatar ? (
                    <img
                      src={user.avatar}
                      alt={user.name}
                      className="w-20 h-20 rounded-full object-cover ring-2 ring-primary-500/20"
                    />
                  ) : (
                    <UserInitials name={user.name} size="lg" className="ring-2 ring-primary-500/20" />
                  )}
                  <div>
                    <h1 className="text-2xl font-bold text-gray-100">{user.name}</h1>
                    <p className="text-gray-400">{user.title} at {user.company}</p>
                  </div>
                </div>

                <div className="space-y-6">
                  <div className="flex items-center gap-6 text-sm text-gray-400">
                    <div className="flex items-center gap-1">
                      <Clock className="w-4 h-4 text-primary-400" />
                      <span>20 min</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <DollarSign className="w-4 h-4 text-secondary-400" />
                      <span className="font-medium">${user.hourly_rate}/hour</span>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-sm font-medium text-gray-300 mb-2">About</h3>
                    <p className="text-gray-400 text-sm">{user.bio}</p>
                  </div>

                  <div>
                    <h3 className="text-sm font-medium text-gray-300 mb-2">Expertise</h3>
                    <div className="flex flex-wrap gap-2">
                      {user.expertise?.map((tag) => (
                        <span
                          key={tag}
                          className="px-3 py-1 bg-primary-500/10 text-primary-300 rounded-full text-xs border border-primary-500/20"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Availability & Booking */}
            <div className="lg:col-span-2 space-y-6">
              <div className="glass-card rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-6">Available Time Slots</h2>

                {error && (
                  <div className="mb-4 p-3 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
                    {error}
                  </div>
                )}

                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                  {timeSlots.length === 0 ? (
                    <div className="col-span-full text-center py-8 text-gray-400">
                      No available time slots
                    </div>
                  ) : (
                    timeSlots.map((slot) => (
                      <button
                        key={slot.id}
                        onClick={() => setSelectedSlot(slot)}
                        className={`p-4 rounded-lg border transition-colors ${
                          selectedSlot?.id === slot.id
                            ? 'bg-primary-500/20 border-primary-500/40 text-primary-300'
                            : 'border-primary-500/20 hover:bg-primary-500/10'
                        }`}
                        disabled={!isAfter(parseISO(slot.start_time), new Date())}
                      >
                        <div className="flex items-center gap-2 mb-2">
                          <Calendar className="w-4 h-4 text-primary-400" />
                          <span className="text-sm">
                            {format(parseISO(slot.start_time), 'MMMM d, yyyy')}
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <Clock className="w-4 h-4 text-primary-400" />
                          <span className="text-sm">
                            {format(parseISO(slot.start_time), 'h:mm a')}
                          </span>
                        </div>
                      </button>
                    ))
                  )}
                </div>
              </div>

              {selectedSlot && (
                <div className="glass-card rounded-lg p-6">
                  <h3 className="text-lg font-semibold mb-4">Book This Slot</h3>
                  
                  <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-300 mb-2">
                      Project Description
                    </label>
                    <textarea
                      value={projectDescription}
                      onChange={(e) => setProjectDescription(e.target.value)}
                      rows={4}
                      className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg text-gray-200 focus:outline-none focus:border-primary-500"
                      placeholder="Describe your project and what kind of feedback you're looking for..."
                      required
                    />
                  </div>

                  <button
                    onClick={handleBookSlot}
                    disabled={isBooking || !projectDescription.trim()}
                    className="w-full btn-outline-gradient disabled:opacity-50"
                  >
                    {isBooking ? 'Booking...' : 'Confirm Booking'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}