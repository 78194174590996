import React from 'react';

interface BadgeProps {
  children: React.ReactNode;
  variant?: 'default' | 'success' | 'error' | 'warning';
}

export function Badge({ children, variant = 'default' }: BadgeProps) {
  const variantStyles = {
    default: 'bg-primary-500/10 text-primary-300 border-primary-500/20',
    success: 'bg-green-500/10 text-green-300 border-green-500/20',
    error: 'bg-red-500/10 text-red-300 border-red-500/20',
    warning: 'bg-yellow-500/10 text-yellow-300 border-yellow-500/20'
  };

  return (
    <span className={`px-2 py-1 text-xs rounded-full border ${variantStyles[variant]}`}>
      {children}
    </span>
  );
}