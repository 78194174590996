import React, { createContext, useContext, useState } from 'react';
import type { User } from '../lib/models/user';

interface ChatState {
  activeChat: {
    bookingId: string;
    otherUser: User;
  } | null;
  openChat: (bookingId: string, otherUser: User) => void;
  closeChat: () => void;
}

const ChatContext = createContext<ChatState | undefined>(undefined);

export function ChatProvider({ children }: { children: React.ReactNode }) {
  const [activeChat, setActiveChat] = useState<ChatState['activeChat']>(null);

  const openChat = (bookingId: string, otherUser: User) => {
    setActiveChat({ bookingId, otherUser });
  };

  const closeChat = () => {
    setActiveChat(null);
  };

  return (
    <ChatContext.Provider value={{ activeChat, openChat, closeChat }}>
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}