import React, { useState, useCallback } from 'react';
import { Header } from '../components/Header';
import { SearchFilters } from '../components/SearchFilters';
import { FeaturedCustomers } from '../components/FeaturedCustomers';
import { BenefitsSection } from '../components/BenefitsSection';
import { FAQSection } from '../components/FAQSection';
import { PricingSection } from '../components/PricingSection';
import { Footer } from '../components/Footer';
import { Star, Users, ArrowRight, Calendar, Coins, MessageSquare } from 'lucide-react';
import { Navigate } from 'react-router-dom';
import { UserCard } from '../components/UserCard';
import { useUsers } from '../hooks/useUsers';
import { useAuth } from '../contexts/AuthContext';

export function HomePage() {
  const { user, isLoading: authLoading } = useAuth();
  const { users, isLoading, error, fetchUsers } = useUsers();

  // Fetch users on component mount
  React.useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState<{
    expertise: string[];
    priceRange: [number, number];
  }>({
    expertise: [],
    priceRange: [0, 200]
  });

  const handleSearch = useCallback((query: string) => {
    setSearchQuery(query);
    fetchUsers({
      expertise: filters.expertise,
      priceRange: filters.priceRange,
      search: query
    });
  }, [filters, fetchUsers]);

  const handleFilterChange = useCallback((newFilters: any) => {
    setFilters(prev => ({
      ...prev,
      expertise: newFilters.expertise || prev.expertise,
      priceRange: newFilters.priceRange || prev.priceRange
    }));
    fetchUsers({
      expertise: newFilters.expertise || filters.expertise,
      priceRange: newFilters.priceRange || filters.priceRange,
      search: searchQuery
    });
  }, [filters, searchQuery, fetchUsers]);

  if (authLoading) {
    return <div>Loading...</div>;
  }

  if (user) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-16">
          <div className="relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-radial from-primary-500/5 via-transparent to-transparent" />
            
            <div className="max-w-7xl mx-auto px-4 py-16 relative z-10">
              <div className="text-center max-w-3xl mx-auto mb-12">
                <div className="flex items-center justify-center gap-8 mb-8">
                  <div className="flex items-center gap-2 px-4 py-2 rounded-full bg-secondary-500/10 border border-secondary-500/20">
                    <Star className="w-4 h-4 text-secondary-400" />
                    <span className="text-sm font-medium">95% Satisfaction Rate</span>
                  </div>
                </div>
                
                <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight">
                  Get <span className="text-primary-400">Actionable Insights</span> From Real Customers
                </h1>
                
                <p className="text-xl text-gray-400 mb-8 leading-relaxed max-w-2xl mx-auto">
                  Connect with potential customers to validate your ideas, improve your product, 
                  and understand your market through meaningful feedback sessions.
                </p>
                
                <div className="flex flex-col sm:flex-row gap-4 justify-center mb-12">
                  <button className="btn-outline-gradient inline-flex items-center gap-2 text-lg px-6 py-3">
                    Schedule Your First Call
                    <ArrowRight className="w-4 h-4" />
                  </button>
                </div>
              </div>

              <div className="flex flex-wrap justify-center gap-4 mb-16">
                <div className="inline-flex items-center gap-3 px-4 py-2 rounded-lg bg-primary-500/10 border border-primary-500/20">
                  <MessageSquare className="w-5 h-5 text-primary-400" />
                  <span className="text-sm font-medium">Direct Customer Access</span>
                </div>
                <div className="inline-flex items-center gap-3 px-4 py-2 rounded-lg bg-secondary-500/10 border border-secondary-500/20">
                  <Calendar className="w-5 h-5 text-secondary-400" />
                  <span className="text-sm font-medium">Structured Feedback</span>
                </div>
                <div className="inline-flex items-center gap-3 px-4 py-2 rounded-lg bg-token-500/10 border border-token-500/20">
                  <Coins className="w-5 h-5 text-token-400" />
                  <span className="text-sm font-medium">Token Economy</span>
                </div>
              </div>

              <SearchFilters onSearch={handleSearch} onFilterChange={handleFilterChange} />
              
              <div className="mt-8 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {isLoading ? (
                  <div className="col-span-full text-center py-12">Loading...</div>
                ) : error ? (
                  <div className="col-span-full text-center py-12 text-red-400">{error}</div>
                ) : (
                  <>
                    {users.map((user) => (
                      <UserCard key={user.id} user={user} />
                    ))}
                    {users.length === 0 && (
                      <div className="col-span-full text-center py-12">
                        <p className="text-gray-400 text-lg">
                          No users found matching your criteria. Try adjusting your filters.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <BenefitsSection />
          <FAQSection />
          <PricingSection />
      </main>
      <Footer />
    </div>
  );
}