import React from 'react';
import { Header } from '../components/Header';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { TeamManagement } from '../components/TeamManagement';
import { DashboardLayout } from '../components/layouts/DashboardLayout';

export function SettingsPage() {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/auth" replace />;
  }

  return (
    <DashboardLayout>
      <main className="pt-20 pb-16">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-3xl font-bold mb-8">Settings</h1>
          
          <div className="glass-card rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-6">Account Settings</h2>
            
            <div className="space-y-8">
              <TeamManagement />
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
}