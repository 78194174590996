import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Cookie, Shield, Settings, Info } from 'lucide-react';

export function CookiePolicyPage() {
  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-24 pb-16">
        <div className="max-w-3xl mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Cookie Policy</h1>
            <p className="text-xl text-gray-400">
              Last updated: March 25, 2024
            </p>
          </div>

          <div className="space-y-12">
            <section>
              <div className="flex items-center gap-3 mb-4">
                <Cookie className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">What Are Cookies</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <p>
                  Cookies are small text files that are placed on your device when you visit our website. 
                  They help us provide you with a better experience by remembering your preferences, 
                  analyzing how you use our platform, and enabling certain features.
                </p>
              </div>
            </section>

            <section>
              <div className="flex items-center gap-3 mb-4">
                <Info className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">Types of Cookies We Use</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <h3 className="text-xl font-semibold mb-2">Essential Cookies</h3>
                <p>
                  These cookies are necessary for the website to function properly. They enable core 
                  functionality such as security, authentication, and session management.
                </p>

                <h3 className="text-xl font-semibold mb-2">Preference Cookies</h3>
                <p>
                  These cookies remember your settings and preferences to enhance your experience 
                  (e.g., language preference, theme settings).
                </p>

                <h3 className="text-xl font-semibold mb-2">Analytics Cookies</h3>
                <p>
                  We use these cookies to understand how visitors interact with our website, helping 
                  us improve our services and user experience.
                </p>
              </div>
            </section>

            <section>
              <div className="flex items-center gap-3 mb-4">
                <Shield className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">How We Protect Your Data</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <p>
                  We take data protection seriously and ensure that:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>All cookie data is encrypted</li>
                  <li>We only collect necessary information</li>
                  <li>Third-party cookies are carefully vetted</li>
                  <li>Cookie data is regularly purged</li>
                </ul>
              </div>
            </section>

            <section>
              <div className="flex items-center gap-3 mb-4">
                <Settings className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">Managing Cookies</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <p>
                  You can control and/or delete cookies as you wish. You can delete all cookies 
                  that are already on your device and you can set most browsers to prevent them 
                  from being placed.
                </p>
                <p>
                  However, if you do this, you may have to manually adjust some preferences every 
                  time you visit our website and some features may not work as intended.
                </p>
              </div>
            </section>
          </div>

          <div className="mt-12 p-6 glass-card rounded-lg">
            <h2 className="text-xl font-bold mb-4">Questions About Cookies?</h2>
            <p className="text-gray-400 mb-6">
              If you have any questions about our use of cookies, please contact us.
            </p>
            <a
              href="mailto:privacy@insightswap.com"
              className="btn-outline-gradient inline-flex items-center gap-2"
            >
              Contact Privacy Team
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}