import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Book, Code, MessageSquare, Coins, Search, ArrowRight } from 'lucide-react';

const guides = [
  {
    title: 'Getting Started',
    icon: Book,
    description: 'Learn the basics of using InsightSwap',
    links: [
      { title: 'Platform Overview', href: '#overview' },
      { title: 'Token System', href: '#tokens' },
      { title: 'Scheduling Sessions', href: '#scheduling' },
    ]
  },
  {
    title: 'Giving Feedback',
    icon: MessageSquare,
    description: 'Best practices for providing valuable feedback',
    links: [
      { title: 'Feedback Guidelines', href: '#guidelines' },
      { title: 'Session Structure', href: '#structure' },
      { title: 'Taking Notes', href: '#notes' },
    ]
  },
  {
    title: 'Token System',
    icon: Coins,
    description: 'Understanding tokens and rewards',
    links: [
      { title: 'Earning Tokens', href: '#earning' },
      { title: 'Using Tokens', href: '#using' },
      { title: 'Token Packages', href: '#packages' },
    ]
  },
  {
    title: 'API Reference',
    icon: Code,
    description: 'Technical documentation for developers',
    links: [
      { title: 'Authentication', href: '#auth' },
      { title: 'Endpoints', href: '#endpoints' },
      { title: 'Webhooks', href: '#webhooks' },
    ]
  }
];

export function DocsPage() {
  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Documentation</h1>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto">
              Everything you need to know about using InsightSwap
            </p>
          </div>

          <div className="max-w-3xl mx-auto mb-12">
            <div className="glass-card rounded-lg p-4 flex items-center gap-3">
              <Search className="w-5 h-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search documentation..."
                className="w-full bg-transparent border-none focus:outline-none text-gray-200 placeholder-gray-400"
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-8 mb-16">
            {guides.map((guide, index) => (
              <div key={index} className="glass-card rounded-lg p-6 hover:border-primary-500/20 transition-all">
                <div className="flex items-start gap-4">
                  <div className="w-12 h-12 rounded-lg bg-primary-500/10 flex items-center justify-center">
                    <guide.icon className="w-6 h-6 text-primary-400" />
                  </div>
                  <div className="flex-1">
                    <h2 className="text-xl font-bold mb-2">{guide.title}</h2>
                    <p className="text-gray-400 mb-4">{guide.description}</p>
                    <ul className="space-y-2">
                      {guide.links.map((link, linkIndex) => (
                        <li key={linkIndex}>
                          <a
                            href={link.href}
                            className="flex items-center gap-2 text-primary-400 hover:text-primary-300 transition-colors group"
                          >
                            <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                            {link.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="glass-card rounded-lg p-8 text-center max-w-2xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">Need Help?</h2>
            <p className="text-gray-400 mb-6">
              Can't find what you're looking for? Our support team is here to help.
            </p>
            <a
              href="mailto:support@insightswap.com"
              className="btn-outline-gradient inline-flex items-center gap-2"
            >
              Contact Support
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}