import React from 'react';
import { Calendar, Clock, User, Check, X, Video, MessageSquare } from 'lucide-react';
import { format } from 'date-fns';
import { useAuth } from '../../contexts/AuthContext';
import { useChat } from '../../contexts/ChatContext';
import { UserInitials } from '../UserInitials';
import { Badge } from '../ui/Badge';
import { VideoCall } from '../VideoCall';
import { dailyService } from '../../lib/video/dailyService';
import type { Booking } from '../../lib/models/booking';

interface CallCardProps {
  booking: Booking;
  onAction?: (action: 'accept' | 'reject') => void;
  isActionInProgress?: boolean;
}

export function CallCard({ booking, onAction, isActionInProgress }: CallCardProps) {
  const { user } = useAuth();
  const { openChat } = useChat();
  const [showVideoCall, setShowVideoCall] = React.useState(false);
  const [isJoiningCall, setIsJoiningCall] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const isProvider = booking.provider_id === user?.id;
  const otherUser = isProvider ? booking.customer : booking.provider;

  const handleJoinCall = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isJoiningCall) return;

    try {
      setIsJoiningCall(true);
      setError(null);

      // Check if room exists
      if (booking.daily_room_url) {
        setShowVideoCall(true);
        return;
      }

      // Create new room
      const roomUrl = await dailyService.createRoom(booking.id);
      if (!roomUrl) throw new Error('Failed to create video room');
      
      setShowVideoCall(true);
    } catch (err) {
      console.error('Error joining video call:', err);
      setError('Failed to join video call. Please try again.');
    } finally {
      setIsJoiningCall(false);
    }
  };

  const handleOpenChat = (e: React.MouseEvent) => {
    e.stopPropagation();
    openChat(booking.id, otherUser);
  };

  // Render video call if active
  if (showVideoCall && (booking.daily_room_url || booking.video_room_url)) {
    return (
      <div className="fixed inset-0 z-50 bg-dark-300/95 flex items-center justify-center p-4">
        <div className="w-full max-w-6xl">
          <VideoCall
            roomUrl={booking.daily_room_url || booking.video_room_url}
            onLeave={() => setShowVideoCall(false)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="glass-card p-6 rounded-lg">
      <div className="space-y-6">
        {/* Header with status */}
        <div className="flex items-center justify-between">
          <Badge variant={
            booking.status === 'confirmed' ? 'success' :
            booking.status === 'cancelled' ? 'error' :
            'default'
          }>
            {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
          </Badge>
        </div>

        {/* User info */}
        <div className="flex items-center gap-3">
          {otherUser.avatar ? (
            <img
              src={otherUser.avatar}
              alt={otherUser.name}
              className="w-10 h-10 rounded-full object-cover"
            />
          ) : (
            <UserInitials name={otherUser.name} size="sm" />
          )}
          <div>
            <p className="font-medium text-gray-200">{otherUser.name}</p>
            <p className="text-sm text-gray-400">{otherUser.title}</p>
          </div>
        </div>

        {/* Date and time */}
        {booking.time_slot && (
          <div className="space-y-2">
            <div className="flex items-start gap-2 text-sm text-gray-400">
              <Calendar className="w-4 h-4 mt-1 flex-shrink-0" />
              <span>{format(new Date(booking.time_slot.start_time), 'MMMM d, yyyy')}</span>
            </div>
            <div className="flex items-start gap-2 text-sm text-gray-400">
              <Clock className="w-4 h-4 mt-1 flex-shrink-0" />
              <span>{format(new Date(booking.time_slot.start_time), 'h:mm a')}</span>
            </div>
          </div>
        )}

        {/* Project description */}
        <div className="text-gray-400 text-sm">
          <strong className="text-gray-300 block mb-1">Project Description:</strong>
          <p>{booking.project_description}</p>
        </div>

        {/* Action buttons */}
        <div className="space-y-3">
          {onAction && booking.status === 'pending' && booking.provider_id === user?.id && (
            <div className="flex items-center gap-3">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onAction('accept');
                }}
                disabled={isActionInProgress}
                className="flex-1 flex items-center justify-center gap-2 px-4 py-2.5 rounded-lg text-sm font-medium bg-gradient-to-r from-green-500/20 to-green-500/10 text-green-300 hover:from-green-500/30 hover:to-green-500/20 border border-green-500/20 transition-all disabled:opacity-50"
              >
                <Check className="w-4 h-4" />
                Accept Request
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onAction('reject');
                }}
                disabled={isActionInProgress}
                className="flex-1 flex items-center justify-center gap-2 px-4 py-2.5 rounded-lg text-sm font-medium bg-gradient-to-r from-red-500/20 to-red-500/10 text-red-300 hover:from-red-500/30 hover:to-red-500/20 border border-red-500/20 transition-all disabled:opacity-50"
              >
                <X className="w-4 h-4" />
                Decline Request
              </button>
            </div>
          )}

          {booking.status === 'confirmed' && (
            <>
              <button
                onClick={handleJoinCall}
                disabled={isJoiningCall}
                className="w-full flex items-center justify-center gap-2 px-4 py-2.5 rounded-lg text-sm font-medium bg-gradient-to-r from-primary-500/20 to-primary-500/10 text-primary-300 hover:from-primary-500/30 hover:to-primary-500/20 border border-primary-500/20 transition-all disabled:opacity-50"
              >
                <Video className="w-4 h-4" />
                <span className="text-sm">
                  {isJoiningCall ? 'Joining Video Call...' : 'Join Video Call'}
                </span>
              </button>

              <button
                onClick={handleOpenChat}
                className="w-full flex items-center justify-center gap-2 px-4 py-2.5 rounded-lg text-sm font-medium bg-gradient-to-r from-secondary-500/20 to-secondary-500/10 text-secondary-300 hover:from-secondary-500/30 hover:to-secondary-500/20 border border-secondary-500/20 transition-all"
              >
                <MessageSquare className="w-4 h-4" />
                <span className="text-sm">Live Chat</span>
              </button>
            </>
          )}
        </div>

        {error && (
          <div className="text-sm text-red-400 bg-red-500/10 border border-red-500/20 rounded-lg p-3 mt-3">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}