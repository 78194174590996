import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { ChatProvider } from './contexts/ChatContext';
import { Notifications } from './components/Notifications';
import { ChatContainer } from './components/chat/ChatContainer';
import { HomePage } from './pages/HomePage';
import { AuthPage } from './pages/AuthPage';
import { DashboardPage } from './pages/DashboardPage';
import { ProfilePage } from './pages/ProfilePage';
import { SettingsPage } from './pages/SettingsPage';
import { ScheduleCallPage } from './pages/ScheduleCallPage';
import { UserProfilePage } from './pages/UserProfilePage';
import { FeatureSuggestionsPage } from './pages/FeatureSuggestionsPage';
import { FeedbackRequestsPage } from './pages/FeedbackRequestsPage';
import { TokenPricingPage } from './pages/TokenPricingPage';
import { AboutPage } from './pages/AboutPage';
import { CareersPage } from './pages/CareersPage';
import { ChangelogPage } from './pages/ChangelogPage';
import { DocsPage } from './pages/DocsPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { TermsPage } from './pages/TermsPage';
import { CookiePolicyPage } from './pages/CookiePolicyPage';
import { useAuthStore } from './lib/auth/authStore';
import { LoadingState } from './components/ui/LoadingState';

function App() {
  const isLoading = useAuthStore(state => state.isLoading);

  if (isLoading) {
    return <LoadingState message="Loading application..." />;
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <NotificationProvider>
          <ChatProvider>
            <Notifications />
            <ChatContainer />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/auth" element={<AuthPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/schedule" element={<ScheduleCallPage />} />
              <Route path="/users/:userId" element={<UserProfilePage />} />
              <Route path="/features" element={<FeatureSuggestionsPage />} />
              <Route path="/projects" element={<FeedbackRequestsPage />} />
              <Route path="/pricing/tokens" element={<TokenPricingPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/careers" element={<CareersPage />} />
              <Route path="/changelog" element={<ChangelogPage />} />
              <Route path="/docs" element={<DocsPage />} />
              <Route path="/legal/privacy" element={<PrivacyPage />} />
              <Route path="/legal/terms" element={<TermsPage />} />
              <Route path="/legal/cookie-policy" element={<CookiePolicyPage />} />
            </Routes>
          </ChatProvider>
        </NotificationProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;