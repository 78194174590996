import React from 'react';
import { Plus, Minus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface FAQItem {
  question: string;
  answer: string;
}

const faqs: FAQItem[] = [
  {
    question: "How does the token system work?",
    answer: "When you join, you receive 2 tokens. Each time you provide feedback, you earn 2 tokens. To receive feedback, you spend 1 token per session. This encourages active participation and ensures high-quality feedback exchange."
  },
  {
    question: "What happens during a feedback session?",
    answer: "Each session is 20 minutes long. You'll connect with a real customer or expert who will review your product, provide actionable insights, and share their perspective. You can share your screen, demo your product, and discuss specific areas you want feedback on."
  },
  {
    question: "How do you ensure quality feedback?",
    answer: "We carefully vet all participants and use a rating system to maintain high standards. After each session, both parties rate the interaction. Users consistently providing valuable feedback earn special badges and higher visibility."
  },
  {
    question: "Can I choose who gives me feedback?",
    answer: "Yes! You can browse profiles and select someone based on their expertise, industry experience, or role. This ensures you get feedback from people who understand your target market."
  },
  {
    question: "What if I need to reschedule?",
    answer: "You can reschedule or cancel a session up to 24 hours before the scheduled time without losing your token. Last-minute cancellations may result in token forfeiture to respect everyone's time."
  },
  {
    question: "Is my information confidential?",
    answer: "Absolutely. All participants agree to our confidentiality terms. You control what you share, and our platform includes features to protect sensitive information during feedback sessions."
  }
];

export function FAQSection() {
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <section className="py-16 bg-dark-300">
      <div className="max-w-3xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
        
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="glass-card rounded-lg overflow-hidden"
            >
              <button
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                className="w-full p-6 flex items-center justify-between text-left hover:bg-primary-500/5 transition-colors"
              >
                <span className="font-medium text-lg">{faq.question}</span>
                {openIndex === index ? (
                  <Minus className="w-5 h-5 text-primary-400 flex-shrink-0" />
                ) : (
                  <Plus className="w-5 h-5 text-primary-400 flex-shrink-0" />
                )}
              </button>
              
              {openIndex === index && (
                <div className="px-6 pb-6">
                  <p className="text-gray-400">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        
        <div className="mt-12 text-center">
          <button
            onClick={() => user ? navigate('/dashboard') : navigate('/auth?signup=true')}
            className="btn-outline-gradient inline-flex items-center gap-2 transition-transform hover:scale-105"
          >
            Get Started Now
          </button>
        </div>
      </div>
    </section>
  );
}