import React, { useState, useEffect } from 'react';
import { Calendar, Clock, ChevronLeft, ChevronRight, Plus, X } from 'lucide-react';
import { format, startOfWeek, addDays, isSameDay, parseISO, isAfter } from 'date-fns';
import { TimeSlotsModel } from '../../lib/models/timeSlots';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../lib/supabase';
import type { TimeSlot } from '../../lib/models/timeSlots';
import * as Popover from '@radix-ui/react-popover';

interface WeeklyAvailability {
  [key: number]: number[];
}

export function AvailabilitySection() {
  const { user } = useAuth();
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Business hours configuration
  const BUSINESS_HOURS = {
    start: 9, // 9 AM
    end: 17,  // 5 PM
  };

  // Get the start of the current week
  const weekStart = startOfWeek(currentWeek, { weekStartsOn: 1 });
  
  // Generate array of 7 days starting from weekStart
  const weekDays = [...Array(7)].map((_, i) => addDays(weekStart, i));

  useEffect(() => {
    if (user?.id) {
      loadTimeSlots();
    }
  }, [user, currentWeek]);

  const loadTimeSlots = async () => {
    try {
      const { data: slots, error } = await supabase
        .from('time_slots')
        .select()
        .eq('provider_id', user!.id)
        .gte('start_time', new Date().toISOString())
        .order('start_time', { ascending: true });

      if (error) throw error;
      setTimeSlots(slots);
    } catch (err) {
      console.error('Error loading time slots:', err);
      setError('Failed to load available time slots');
    }
  };

  const getTimeSlotForDateAndHour = (date: Date, hour: number) => {
    const matchingSlots = timeSlots.filter(slot => {
      const slotDate = parseISO(slot.start_time);
      return isSameDay(slotDate, date) && slotDate.getHours() === hour;
    });
    
    // Return the first matching slot if any exist
    return matchingSlots[0] || null;
  };

  const handlePreviousWeek = () => {
    setCurrentWeek(prev => addDays(prev, -7));
  };

  const handleNextWeek = () => {
    setCurrentWeek(prev => addDays(prev, 7));
  };

  const handleAddTimeSlot = async (date: Date, hour: number) => {
    if (!user?.id) return;
    
    try {
      setError(null);
      
      const startTime = new Date(date);
      startTime.setHours(hour, 0, 0, 0);
      
      // Ensure we're working with local time
      const now = new Date();
      if (startTime <= now) {
        setError('Cannot add slots in the past');
        return;
      }
      
      const endTime = new Date(startTime);
      endTime.setMinutes(startTime.getMinutes() + 20);
      
      const { error: slotError } = await supabase
        .from('time_slots')
        .insert([{
          provider_id: user.id,
          start_time: startTime.toISOString(),
          end_time: endTime.toISOString(),
          is_booked: false,
          duration_minutes: 20
        }]);

      if (slotError) throw slotError;
      
      setSuccess('Time slot added successfully');
      setTimeout(() => setSuccess(null), 3000);
      await loadTimeSlots();
    } catch (err) {
      console.error('Error adding time slot:', err);
      setError('Failed to add time slot');
    }
  };

  const handleRemoveTimeSlot = async (slotId: string) => {
    try {
      const { error } = await supabase
        .from('time_slots')
        .delete()
        .eq('id', slotId);
      
      if (error) throw error;
      
      setTimeSlots(prev => prev.filter(slot => slot.id !== slotId));
      setSuccess('Time slot removed successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      console.error('Error removing time slot:', err);
      setError('Failed to remove time slot');
    }
  };

  return (
    <div className="glass-card rounded-lg p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold">Availability</h2>
        <div className="flex items-center gap-2">
          <button
            onClick={handlePreviousWeek}
            className="p-2 hover:bg-primary-500/10 rounded-lg transition-colors"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <span className="text-sm text-gray-400">
            {format(weekStart, 'MMMM d')} - {format(addDays(weekStart, 6), 'MMMM d, yyyy')}
          </span>
          <button
            onClick={handleNextWeek}
            className="p-2 hover:bg-primary-500/10 rounded-lg transition-colors"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-4 mb-6">
        {weekDays.map((day) => (
          <div key={day.toISOString()} className="text-center">
            <div className="text-sm font-medium text-gray-400">
              {format(day, 'EEE')}
            </div>
            <div className="text-lg">
              {format(day, 'd')}
            </div>
          </div>
        ))}
      </div>
      
      <div className="grid grid-cols-7 gap-4">
        {weekDays.map((day) => (
          <div key={day.toISOString()} className="space-y-2">
            {Array.from(
              { length: BUSINESS_HOURS.end - BUSINESS_HOURS.start },
              (_, i) => BUSINESS_HOURS.start + i
            ).map((hour) => {
              const existingSlot = getTimeSlotForDateAndHour(day, hour);
              const isInPast = !isAfter(new Date(day).setHours(hour), new Date());
              
              return (
                <button
                  key={`${day.toISOString()}-${hour}`}
                  onClick={() => existingSlot 
                    ? handleRemoveTimeSlot(existingSlot.id)
                    : handleAddTimeSlot(day, hour)
                  }
                  disabled={isInPast || (existingSlot?.is_booked)}
                  className={`
                    w-full px-2 py-1.5 text-xs rounded-lg transition-colors
                    ${existingSlot?.is_booked
                      ? 'bg-primary-500/10 text-primary-300 cursor-not-allowed'
                      : existingSlot
                        ? 'bg-green-500/10 text-green-300 hover:bg-red-500/10 hover:text-red-300'
                        : isInPast
                          ? 'opacity-50 cursor-not-allowed'
                          : 'hover:bg-primary-500/10'
                    }
                  `}
                >
                  {format(new Date().setHours(hour), 'h:mm a')}
                </button>
              );
            })}
          </div>
        ))}
      </div>

      {error && (
        <div className="mt-4 p-3 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
          {error}
        </div>
      )}

      {success && (
        <div className="mt-4 p-3 bg-green-500/10 border border-green-500/20 rounded-lg text-green-400">
          {success}
        </div>
      )}

      <div className="mt-6 pt-6 border-t border-primary-500/20">
        <div className="flex items-center justify-between text-sm text-gray-400">
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-primary-500/10 rounded-full border border-primary-500/20" />
              <span>Booked</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-green-500/10 rounded-full border border-green-500/20" />
              <span>Available</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-gray-500/10 rounded-full border border-gray-500/20" />
              <span>Add Slot</span>
            </div>
          </div>
          <span className="text-xs">All times shown in your local timezone</span>
        </div>
      </div>
    </div>
  );
}