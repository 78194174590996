import React from 'react';
import { Users, MessageSquare, Star, Trophy, TrendingUp, Target } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../lib/supabase';

interface Stats {
  totalCalls: number;
  feedbackGiven: number;
  feedbackReceived: number;
  tokensEarned: number;
  completionRate: number;
  averageRating: number;
}

export function StatsSection() {
  const { user } = useAuth();
  const [stats, setStats] = React.useState<Stats>({
    totalCalls: 0,
    feedbackGiven: 0,
    feedbackReceived: 0,
    tokensEarned: 0,
    completionRate: 0,
    averageRating: 0
  });
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (user?.id) {
      loadStats();
    }
  }, [user]);

  const loadStats = async () => {
    try {
      // Load bookings where user is provider (feedback given)
      const { data: givenBookings, error: givenError } = await supabase
        .from('bookings')
        .select('*')
        .eq('provider_id', user!.id);

      if (givenError) throw givenError;

      // Load bookings where user is customer (feedback received)
      const { data: receivedBookings, error: receivedError } = await supabase
        .from('bookings')
        .select('*')
        .eq('customer_id', user!.id);

      if (receivedError) throw receivedError;

      const completedGiven = givenBookings?.filter(b => b.status === 'completed') || [];
      const completedReceived = receivedBookings?.filter(b => b.status === 'completed') || [];

      setStats({
        totalCalls: (givenBookings?.length || 0) + (receivedBookings?.length || 0),
        feedbackGiven: givenBookings?.length || 0,
        feedbackReceived: receivedBookings?.length || 0,
        tokensEarned: completedGiven.length * 2, // 2 tokens per completed feedback session
        completionRate: Math.round(
          ((completedGiven.length + completedReceived.length) /
            (givenBookings?.length + receivedBookings?.length || 1)) * 100
        ),
        averageRating: 4.8 // Placeholder for now
      });
    } catch (err) {
      console.error('Error loading stats:', err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="glass-card rounded-lg p-6">Loading stats...</div>;
  }

  return (
    <div className="space-y-6">
      {/* Quick stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="glass-card rounded-lg p-6">
          <div className="flex items-start justify-between">
            <div>
              <p className="text-sm text-gray-400 mb-1">Total Feedback Sessions</p>
              <p className="text-3xl font-bold gradient-text">{stats.totalCalls}</p>
            </div>
            <div className="w-10 h-10 rounded-lg bg-primary-500/10 flex items-center justify-center">
              <MessageSquare className="w-5 h-5 text-primary-400" />
            </div>
          </div>
          <div className="mt-4 flex items-center gap-2 text-sm">
            <div className="px-2 py-1 rounded-full bg-token-500/10 text-token-400">
              +{stats.tokensEarned} tokens earned
            </div>
          </div>
        </div>

        <div className="glass-card rounded-lg p-6">
          <div className="flex items-start justify-between">
            <div>
              <p className="text-sm text-gray-400 mb-1">Completion Rate</p>
              <p className="text-3xl font-bold gradient-text">{stats.completionRate}%</p>
            </div>
            <div className="w-10 h-10 rounded-lg bg-secondary-500/10 flex items-center justify-center">
              <Target className="w-5 h-5 text-secondary-400" />
            </div>
          </div>
          <div className="mt-4 flex items-center gap-2 text-sm">
            <TrendingUp className="w-4 h-4 text-green-400" />
            <span className="text-gray-400">Keep it up!</span>
          </div>
        </div>

        <div className="glass-card rounded-lg p-6">
          <div className="flex items-start justify-between">
            <div>
              <p className="text-sm text-gray-400 mb-1">Average Rating</p>
              <p className="text-3xl font-bold gradient-text">{stats.averageRating}</p>
            </div>
            <div className="w-10 h-10 rounded-lg bg-yellow-500/10 flex items-center justify-center">
              <Star className="w-5 h-5 text-yellow-400" />
            </div>
          </div>
          <div className="mt-4 flex items-center gap-2 text-sm">
            <Trophy className="w-4 h-4 text-yellow-400" />
            <span className="text-gray-400">Top rated feedback provider</span>
          </div>
        </div>
      </div>

      {/* Detailed stats */}
      <div className="glass-card rounded-lg p-6">
        <h3 className="text-lg font-semibold mb-6">Feedback Activity</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <div className="flex items-center justify-between mb-2">
              <p className="text-sm text-gray-400">Feedback Given</p>
              <p className="text-lg font-semibold">{stats.feedbackGiven}</p>
            </div>
            <div className="h-3 bg-dark-400 rounded-full overflow-hidden">
              <div 
                className="h-full bg-gradient-to-r from-primary-500 to-secondary-500"
                style={{ width: `${Math.min((stats.feedbackGiven / 10) * 100, 100)}%` }}
              />
            </div>
            <p className="text-xs text-gray-500 mt-2">
              {10 - stats.feedbackGiven} more to reach next milestone
            </p>
          </div>

          <div>
            <div className="flex items-center justify-between mb-2">
              <p className="text-sm text-gray-400">Feedback Received</p>
              <p className="text-lg font-semibold">{stats.feedbackReceived}</p>
            </div>
            <div className="h-3 bg-dark-400 rounded-full overflow-hidden">
              <div 
                className="h-full bg-gradient-to-r from-primary-500 to-secondary-500"
                style={{ width: `${Math.min((stats.feedbackReceived / 5) * 100, 100)}%` }}
              />
            </div>
            <p className="text-xs text-gray-500 mt-2">
              {5 - stats.feedbackReceived} more to unlock special perks
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}