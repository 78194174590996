import { z } from 'zod';
import { supabase } from './supabase';
import { UserModel } from './models/user';

export const loginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
});

export const registerSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
  name: z.string().min(2),
  title: z.string().optional(),
  company: z.string().optional(),
  bio: z.string().optional(),
  expertise: z.array(z.string()).optional(),
  hourlyRate: z.number().optional(),
});

export async function register(data: z.infer<typeof registerSchema>) {
  const { data: authData, error: signUpError } = await supabase.auth.signUp({
    email: data.email,
    password: data.password,
    options: {
      data: {
        name: data.name,
        title: data.title,
        company: data.company,
        bio: data.bio,
        expertise: data.expertise || [],
        hourlyRate: data.hourlyRate
      }
    }
  });

  if (signUpError) throw signUpError;

  if (authData.user) {
    await UserModel.createProfile(authData.user.id, {
      email: data.email,
      name: data.name,
      title: data.title,
      company: data.company,
      bio: data.bio,
      expertise: data.expertise || [],
      hourly_rate: data.hourlyRate,
      tokens: 2,
      role: 'user'
    });
  }

  return authData;
}

export async function login(data: z.infer<typeof loginSchema>) {
  const { data: authData, error } = await supabase.auth.signInWithPassword({
    email: data.email,
    password: data.password
  });

  if (error) throw error;
  return authData;
}

export async function getCurrentUser() {
  const { data: { session } } = await supabase.auth.getSession();
  if (!session?.user) return null;

  return UserModel.getCurrentUser();
}

export async function logout() {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
}