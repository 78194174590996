import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Scale, Shield, FileText, MessageSquare } from 'lucide-react';

export function TermsPage() {
  return (
    <div className="min-h-screen bg-dark-300">
      <Header />
      <main className="pt-24 pb-16">
        <div className="max-w-3xl mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Terms of Service</h1>
            <p className="text-xl text-gray-400">
              Last updated: March 25, 2024
            </p>
          </div>

          <div className="space-y-12">
            <section>
              <div className="flex items-center gap-3 mb-4">
                <Scale className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">Agreement to Terms</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <p>
                  By accessing or using InsightSwap, you agree to be bound by these Terms of Service
                  and our Privacy Policy. If you disagree with any part of the terms, you may not
                  access our services.
                </p>
              </div>
            </section>

            <section>
              <div className="flex items-center gap-3 mb-4">
                <MessageSquare className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">Feedback Sessions</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <h3 className="text-xl font-semibold mb-2">Session Rules</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Sessions must be conducted professionally and respectfully</li>
                  <li>Feedback should be constructive and actionable</li>
                  <li>Sessions must start and end on time</li>
                  <li>Cancellations must be made at least 24 hours in advance</li>
                </ul>

                <h3 className="text-xl font-semibold mb-2">Token System</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Tokens are non-refundable and non-transferable</li>
                  <li>Tokens have no monetary value</li>
                  <li>Token rewards are subject to quality requirements</li>
                  <li>Abuse of the token system may result in account suspension</li>
                </ul>
              </div>
            </section>

            <section>
              <div className="flex items-center gap-3 mb-4">
                <Shield className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">Confidentiality</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <p>
                  All information shared during feedback sessions is considered confidential. Users agree to:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Not disclose any confidential information</li>
                  <li>Not use information for competitive advantage</li>
                  <li>Not record sessions without explicit permission</li>
                  <li>Report any confidentiality breaches immediately</li>
                </ul>
              </div>
            </section>

            <section>
              <div className="flex items-center gap-3 mb-4">
                <FileText className="w-6 h-6 text-primary-400" />
                <h2 className="text-2xl font-bold">Account Terms</h2>
              </div>
              <div className="text-gray-300 space-y-4">
                <p>Users are responsible for:</p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Maintaining accurate account information</li>
                  <li>Protecting account credentials</li>
                  <li>All activities that occur under their account</li>
                  <li>Reporting unauthorized access immediately</li>
                </ul>
              </div>
            </section>
          </div>

          <div className="mt-12 p-6 glass-card rounded-lg">
            <h2 className="text-xl font-bold mb-4">Questions About Terms?</h2>
            <p className="text-gray-400 mb-6">
              If you have any questions about these Terms of Service, please contact us.
            </p>
            <a
              href="mailto:legal@insightswap.com"
              className="btn-outline-gradient inline-flex items-center gap-2"
            >
              Contact Legal Team
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}