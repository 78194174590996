import React from 'react';
import { DailyVideo, useParticipantIds, useLocalParticipant } from '@daily-co/daily-react';

export function VideoGrid() {
  const participantIds = useParticipantIds();
  const localParticipant = useLocalParticipant();
  const participants = localParticipant 
    ? [localParticipant.session_id, ...participantIds.filter(id => id !== localParticipant.session_id)] 
    : participantIds;

  return (
    <div className="absolute inset-0 grid grid-cols-2 gap-4 p-4">
      {participants.map((participantId) => (
        <div key={participantId} className="relative aspect-video bg-dark-300 rounded-lg overflow-hidden">
          <DailyVideo
            automirror
            sessionId={participantId}
            className="w-full h-full object-cover"
          />
        </div>
      ))}
    </div>
  );
}