import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../lib/auth/authService';
import { useAuthStore } from '../lib/auth/authStore';

interface AuthContextType {
  user: ReturnType<typeof useAuthStore>['user'];
  isLoading: ReturnType<typeof useAuthStore>['isLoading'];
  error: ReturnType<typeof useAuthStore>['error'];
  signUp: (email: string, password: string, name: string) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const { user, isLoading, error } = useAuthStore();

  const signUp = async (email: string, password: string, name: string) => {
    try {
      await authService.signUp(email, password, name);
      navigate('/dashboard');
    } catch (error) {
      throw error;
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      await authService.signIn(email, password);
      navigate('/dashboard');
    } catch (error) {
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await authService.signOut();
      navigate('/');
    } catch (error) {
      throw error;
    }
  };

  const value = {
    user,
    isLoading,
    error,
    signUp,
    signIn,
    signOut
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}