import React from 'react';
import { useChat } from '../../contexts/ChatContext';
import { ChatPopup } from './ChatPopup';

export function ChatContainer() {
  const { activeChat, closeChat } = useChat();

  if (!activeChat) return null;

  return (
    <ChatPopup
      bookingId={activeChat.bookingId}
      otherUser={activeChat.otherUser}
      onClose={closeChat}
    />
  );
}