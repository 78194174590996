import React from 'react';
import { ProfileSection } from '../components/dashboard/ProfileSection';
import { AvailabilitySection } from '../components/dashboard/AvailabilitySection';
import { StatsSection } from '../components/dashboard/StatsSection';
import { useAuth } from '../contexts/AuthContext';
import { Link, Navigate } from 'react-router-dom';
import { DashboardLayout } from '../components/layouts/DashboardLayout';

export function DashboardPage() {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/auth" replace />;
  }

  return (
    <DashboardLayout>
      <main className="p-8 bg-dark-300">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-3xl font-bold mb-8">Dashboard</h1>
          
          <div className="space-y-6">
            <StatsSection />
            
            <div className="glass-card rounded-lg p-6">
              <div className="flex items-start gap-8">
                <div className="flex-shrink-0">
                  {user.avatar ? (
                    <img
                      src={user.avatar}
                      alt={user.name}
                      className="w-32 h-32 rounded-full object-cover ring-4 ring-primary-500/20"
                    />
                  ) : (
                    <div className="w-32 h-32 rounded-full bg-primary-500/20 flex items-center justify-center text-3xl font-bold text-primary-300">
                      {user.name?.charAt(0)}
                    </div>
                  )}
                </div>
                
                <div className="flex-1">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h2 className="text-2xl font-bold text-gray-100">{user.name}</h2>
                      <p className="text-gray-400">{user.title} at {user.company}</p>
                    </div>
                    <Link
                      to="/profile"
                      className="btn-outline-gradient"
                    >
                      Edit Profile
                    </Link>
                  </div>
                  
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-sm font-medium text-gray-400 mb-1">Bio</h3>
                      <p className="text-gray-300">{user.bio || 'No bio added yet'}</p>
                    </div>
                    
                    <div>
                      <h3 className="text-sm font-medium text-gray-400 mb-2">Expertise</h3>
                      <div className="flex flex-wrap gap-2">
                        {user.expertise?.map((tag) => (
                          <span
                            key={tag}
                            className="px-3 py-1 bg-primary-500/10 text-primary-300 rounded-full text-sm border border-primary-500/20"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <AvailabilitySection />
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
}