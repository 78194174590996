import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowUp, ArrowDown, Plus, Loader2 } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { useNotifications } from '../contexts/NotificationContext';
import { format } from 'date-fns';
import { UserInitials } from './UserInitials';

interface FeatureSuggestion {
  id: string;
  title: string;
  description: string;
  status: 'pending' | 'planned' | 'in_progress' | 'completed' | 'rejected';
  user_id: string;
  votes_count: number;
  created_at: string;
  user?: {
    name: string;
    avatar?: string;
  };
  user_vote?: {
    vote_type: 'up' | 'down';
  }[];
}

export function FeatureSuggestions() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [suggestions, setSuggestions] = useState<FeatureSuggestion[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showNewForm, setShowNewForm] = useState(false);
  const [newSuggestion, setNewSuggestion] = useState({
    title: '',
    description: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    loadSuggestions();
  }, []);

  const loadSuggestions = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('feature_suggestions')
        .select(`
          *,
          user:profiles(name, avatar),
          user_votes:feature_votes(vote_type)
        `)
        .order('votes_count', { ascending: false });

      if (error) throw error;
      setSuggestions(data || []);
    } catch (err) {
      console.error('Error loading suggestions:', err);
      setError('Failed to load feature suggestions');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      navigate('/auth');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const { error } = await supabase
        .from('feature_suggestions')
        .insert([{
          title: newSuggestion.title.trim(),
          description: newSuggestion.description.trim(),
          user_id: user.id
        }]);

      if (error) throw error;

      addNotification('success', 'Feature suggestion submitted successfully');
      setShowNewForm(false);
      setNewSuggestion({ title: '', description: '' });
      await loadSuggestions();
    } catch (err) {
      console.error('Error submitting suggestion:', err);
      addNotification('error', 'Failed to submit feature suggestion');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVote = async (suggestionId: string, voteType: 'up' | 'down') => {
    if (!user) {
      navigate('/auth');
      return;
    }

    try {
      const suggestion = suggestions.find(s => s.id === suggestionId);
      const currentVote = suggestion?.user_vote?.[0]?.vote_type;
      
      if (currentVote === voteType) {
        // Remove vote
        const { error } = await supabase
          .from('feature_votes')
          .delete()
          .eq('feature_id', suggestionId)
          .eq('user_id', user.id);
        
        if (error) throw error;
      } else {
        // Upsert vote
        const { error } = await supabase
          .from('feature_votes')
          .upsert({
            feature_id: suggestionId,
            user_id: user.id,
            vote_type: voteType
          }, {
            onConflict: 'feature_id,user_id'
          });

        if (error) throw error;
      }

      await loadSuggestions();
    } catch (err) {
      console.error('Error voting:', err);
      addNotification('error', 'Failed to register vote');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-12">
        <Loader2 className="w-6 h-6 animate-spin text-primary-400" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between mb-6">
        {!showNewForm && (
          <button
            onClick={() => user ? setShowNewForm(true) : navigate('/auth')}
            className="btn-outline-gradient inline-flex items-center gap-2"
          >
            <Plus className="w-4 h-4" />
            <span>Suggest Feature</span>
          </button>
        )}
      </div>

      {showNewForm && (
        <div className="glass-card rounded-lg p-6 mb-8">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Title
              </label>
              <input
                type="text"
                value={newSuggestion.title}
                onChange={e => setNewSuggestion(prev => ({ ...prev, title: e.target.value }))}
                className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Description
              </label>
              <textarea
                value={newSuggestion.description}
                onChange={e => setNewSuggestion(prev => ({ ...prev, description: e.target.value }))}
                rows={4}
                className="w-full px-3 py-2 bg-dark-400 border border-primary-500/20 rounded-lg focus:outline-none focus:border-primary-500"
                required
              />
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={() => setShowNewForm(false)}
                className="px-4 py-2 text-gray-300 border border-primary-500/20 rounded-lg hover:bg-primary-500/5"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn-outline-gradient disabled:opacity-50"
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="w-4 h-4 animate-spin" />
                    <span>Submitting...</span>
                  </>
                ) : (
                  'Submit Suggestion'
                )}
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="space-y-4">
        {suggestions.map(suggestion => {
          const userVote = suggestion.user_vote?.[0]?.vote_type;
          
          return (
            <div key={suggestion.id} className="glass-card rounded-lg p-6">
              <div className="flex gap-6">
                <div className="flex flex-col items-center gap-2">
                  <button
                    onClick={() => handleVote(suggestion.id, 'up')}
                    className={`p-1 rounded hover:bg-primary-500/10 ${
                      userVote === 'up' ? 'text-primary-400' : 'text-gray-400'
                    }`}
                  >
                    <ArrowUp className="w-5 h-5" />
                  </button>
                  <span className="text-sm font-medium">
                    {suggestion.votes_count}
                  </span>
                  <button
                    onClick={() => handleVote(suggestion.id, 'down')}
                    className={`p-1 rounded hover:bg-primary-500/10 ${
                      userVote === 'down' ? 'text-primary-400' : 'text-gray-400'
                    }`}
                  >
                    <ArrowDown className="w-5 h-5" />
                  </button>
                </div>

                <div className="flex-1">
                  <div className="flex items-start justify-between gap-4 mb-2">
                    <h3 className="text-lg font-semibold">{suggestion.title}</h3>
                    <span className={`px-2 py-1 text-xs rounded-full ${
                      suggestion.status === 'pending'
                        ? 'bg-yellow-500/10 text-yellow-300'
                        : suggestion.status === 'planned'
                        ? 'bg-blue-500/10 text-blue-300'
                        : suggestion.status === 'in_progress'
                        ? 'bg-primary-500/10 text-primary-300'
                        : suggestion.status === 'completed'
                        ? 'bg-green-500/10 text-green-300'
                        : 'bg-red-500/10 text-red-300'
                    }`}>
                      {suggestion.status.replace('_', ' ')}
                    </span>
                  </div>

                  <p className="text-gray-300 mb-4">{suggestion.description}</p>

                  <div className="flex items-center gap-4 text-sm text-gray-500">
                    <div className="flex items-center gap-2">
                      {suggestion.user?.avatar ? (
                        <img
                          src={suggestion.user.avatar}
                          alt={suggestion.user.name}
                          className="w-6 h-6 rounded-full"
                        />
                      ) : (
                        <UserInitials name={suggestion.user?.name || ''} size="sm" />
                      )}
                      <span>{suggestion.user?.name || 'Anonymous'}</span>
                    </div>
                    <span>•</span>
                    <span>{format(new Date(suggestion.created_at), 'PP')}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {suggestions.length === 0 && (
          <div className="text-center py-12 text-gray-400">
            No suggestions yet. Be the first to suggest a feature!
          </div>
        )}
      </div>
    </div>
  );
}